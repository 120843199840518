import React from 'react'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <>
     {/* Header Start */}
<div className="container-fluid bg-breadcrumb" style={{width:"100%", height:"200px"}} >
  <div className="container text-center py-0 " >
    <h3 className="text-white display-6 mb-0 wow fadeInDown" data-wow-delay="0.1s">About Us</h3>
    <ol className="breadcrumb justify-content-center text-white mb-0 wow fadeInDown" data-wow-delay="0.3s">
      <li className="breadcrumb-item"><Link to="/" className="text-white">Home</Link></li>
      {/* <li className="breadcrumb-item"><Link href="#" className="text-white">Pages</Link></li> */}
      <li className="breadcrumb-item active text-secondary">About Us</li>
    </ol>    
  </div>
</div>
{/* Header End */}
  {/* About Start */}
  <div className="container-fluid py-5">
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/training/2.png" className="img-fluid w-100 "  alt="Image" />
            {/* <img src="img/about-3.jpg" className="img-fluid w-100 border-bottom border-5 border-primary" style={{borderTopRightRadius: 300, borderTopLeftRadius: 300}} alt="Image" /> */}
          </div>
        </div>
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h5 className="sub-title pe-3">About the company</h5>
          <h1 className="display-5 mb-4">SARS ERP </h1>
          <p className="mb-4">Our expertise is in providing integrated ERP Software for Retail, Distribution 
            and Manufacturing to thousands of customers till date, helped them to meet their 
            business goals.</p>
          <div className="row gy-4 align-items-center">
            <div className="col-12 col-sm-6 d-flex align-items-center">
              <i className="fas fa-map-marked-alt fa-3x text-secondary" />
              <h5 className="ms-4">Best ERP Software</h5>
            </div>
            <div className="col-12 col-sm-6 d-flex align-items-center">
              <i className="fas fa-passport fa-3x text-secondary" />
              <h5 className="ms-4">Websites</h5>
            </div>
            <div className="col-4 col-md-3">
              <div className="bg-light text-center rounded p-3">
                <div className="mb-2">
                  <i className="fas fa-ticket-alt fa-4x text-primary" />
                </div>
                <h1 className="display-5 fw-bold mb-2">10</h1>
                <p className="text-muted mb-0">Years of Experience</p>
              </div>
            </div>
            <div className="col-8 col-md-9">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Offer 100 % Genuine Assistance</p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> It's Faster &amp; Reliable Execution</p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Expert Developer Team</p>
              </div>
              <div className="d-flex flex-wrap">
                <div id="phone-tada" className="d-flex align-items-center justify-content-center me-4">
                  <a href className="position-relative wow tada" data-wow-delay=".9s">
                    <i className="fa fa-phone-alt text-primary fa-3x" />
                    <div className="position-absolute" style={{top: 0, left: 25}}>
                      <span><i className="fa fa-comment-dots text-secondary" /></span>
                    </div>
                  </a>
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <span className="text-primary">Have any questions?</span>
                  <span className="text-secondary fw-bold fs-5" style={{letterSpacing: 2}}>Free: 0180-7964198</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* About End */}
    </>
  )
}

export default About