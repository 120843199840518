import React from 'react'
import { Link } from 'react-router-dom'

const WebDevelopmentTraining = () => {
  return (
    <>
    {/* Hero Start */}
<div className="container-fluid bg-breadcrumb" style={{width:"100%", height:"200px"}} >
 <div className="container text-center py-0 " >
   <h3 className="text-white display-6 mb-0 wow fadeInDown" data-wow-delay="0.1s">Web Development Training</h3>
   <ol className="breadcrumb justify-content-center text-white mb-0 wow fadeInDown" data-wow-delay="0.3s">
     <li className="breadcrumb-item"><Link to="/" className="text-white">Home</Link></li>
     {/* <li className="breadcrumb-item"><Link href="#" className="text-white">Pages</Link></li> */}
     <li className="breadcrumb-item active text-secondary">Web Development Training</li>
   </ol>
 </div>
</div>
{/* Hero End */}
 {/* Section Start */}
 <div className="container-fluid py-5">
   <div className="container py-3">
     <div className="row g-5">
       <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
         <h5 className="sub-title pe-3">Web App Development Training</h5>
         <h1 className="display-6 mb-4">Web App Development Training</h1>
         <p className="mb-5"> Our Website Application (Production Planning - PP) training program in Panipat Haryana is specially designed for Students, Under-Graduates, and Graduates, Working Professionals, and Freelancers.</p>
         <div className="row gy-4 align-items-center">
           <div className="col-12 col-sm-6 d-flex align-items-center">
             <i className="fas fa-map-marked-alt fa-3x text-secondary" />
             <h5 className="ms-4">Best Trainers</h5>
           </div>
           <div className="col-12 col-sm-6 d-flex align-items-center">
             <i className="fas fa-passport fa-3x text-secondary" />
             <h5 className="ms-4">Web App Development Training</h5>
           </div>
           <div className="col-4 col-md-3">
             <div className="bg-light text-center rounded p-3">
               <div className="mb-2">
                 <i className="fas fa-ticket-alt fa-4x text-primary" />
               </div>
               <h1 className="display-5 fw-bold mb-2">5+</h1>
               <p className="text-muted mb-0">Years of Experience</p>
             </div>
           </div>
           <div className="col-8 col-md-9" >
             <div className="mb-4" style={{paddingLeft:"50px"}}>
               <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Website Solution Manager</p>
               <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Advance MongoDB</p>
               <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Advance JavaScript</p>
               <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Front,Back-End Development</p>
               </div>
             <div className="d-flex flex-wrap">
               <div id="phone-tada" className="d-flex align-items-center justify-content-center me-4">
                 <a href className="position-relative wow tada" data-wow-delay=".9s">
                   <i className="fa fa-phone-alt text-primary fa-3x" />
                   <div className="position-absolute" style={{top: 0, left: 25}}>
                     <span><i className="fa fa-comment-dots text-secondary" /></span>
                   </div>
                 </a>
               </div>
               <div className="d-flex flex-column justify-content-center">
                 <span className="text-primary">Offer 100 % Genuine Assistance [Have any query?]</span>
                 <span className="text-secondary fw-bold fs-5" style={{letterSpacing: 2}}>Free: 0180-7964198</span>
               </div>
             </div>
           </div>
         </div>
       </div>

       <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
         <div className="bg-light rounded">
           <img src="img/training/2.png" className="img-fluid w-100 "  alt="Image" />
         </div>
       </div>
     </div>
     <div className="row py-5">
      <div className=" wow fadeInRight py-3" data-wow-delay="0.3s">
         <h4 className="display-6 mb-4">Web App Development Training in Panipat Haryana</h4>
         
         <p className="text-primary">Are you Looking Best institute for <b> Web App Development Training Course in Panipat Haryana?</b> SARS ERP offers Web App Development Training classes with live projects by the expert trainer in Panipat Haryana Sector 16A. Our <b> Web App Development (Production Planning) training program in Panipat Haryana </b> is specially designed for Students, Under-Graduates, Graduates, Working Professionals, and Freelancers. We provide end to end learning on <b> Web App Development </b> Domain with deeper dives for creating a winning career for every profile.</p>
         
         <p className="text-primary py-2 h4 mb-2"><i className="fa fa-caret-right text-secondary me-2" /> 
         Why To Enroll In Our Web App Development Training Course in Panipat Haryana? </p>
         
         <p className="text-primary" >We Focus on Innovative ideas, High-quality Training, Smart Classes, 100% job assistance, and Opening the doors of opportunities. Our Web App Development Trainees are working across the nation. We at SARS ERP, No#1 Web App Development Course in Panipat Haryana with 100% Placement. 40+ Modules, 6 Months Course. Certified Trainers with Over 10,000 Students Trained in <b> Web App Development Course in Panipat Haryana </b>.</p>
        
         <p className="text-primary py-2 h3 mb-2"><i className="fa fa-caret-right text-secondary me-2" /> 
         What is Web App Development? </p>
         <p className="text-primary" >Web App Development (Website) provides facilities for planning, implementation, quality, and plant administration.</p>
         <p className="text-primary" >Web App Development is determined by keeping in mind the resource planning, sales and procedure planning and demand of the product. Having resolute the information, Web Application generates Master Production Schedule. Following are few screenshots from eresource Web Application, which helps identify the resources for MPS. A brief flow chart of eresource Web Application, Production Planning, and Control algorithm is also given below for your ready reference.</p>
         <p className="text-primary" >Traditionally production planning software was a separate package or an independently functioning module in Enterprise. The level of administration needed was high and reporting took a long time.</p>
         <p className="text-primary" >Enterprise has a different approach to supporting MRP production planning. Being tightly integrated into other processes makes the information support to production very efficient. It uses the same principle as other modules and processes, so it is very easy to add production to an existing installation.</p>
         <p className="text-primary" >You will be able to run all standard production operations like technology base, production logistics, planning, and scheduling. On top of that, you will find out how easy it is to handle and track batches and serial numbers. A part of supported quality management processes is easy tracking of working hours and productivity through CRM integration and easily getting very detailed post-calculation and other statistical reports, which take a long time to create competitive solutions.</p>
         <p className="text-primary" >You can avail of Web App Development from various reliable online websites. Among these providers, Ducantindia.com is one of the major providers of Web App Development services.</p>
         
         <p className="text-primary py-2 h3 mb-2"><i className="fa fa-caret-right text-secondary me-2" /> 
         Web App Development Overview : </p>
         <p className="text-primary" ><b>WEB APP DEVELOPMENT </b></p>
         <p className="text-primary" ><b>MANUFACTURING PLANNING AND EXECUTION </b></p>
         <p className="text-primary" ><b>MATERIAL MASTER CREATION </b></p>
         <p className="text-primary" ><b>BILL OF MATERIAL </b></p>
         <p className="text-primary" ><b>MATERIAL REQUIREMENT PLANNING  </b></p>
         <p className="text-primary" ><b>MASTER PRODUCTION SCHEDULING </b></p>
         <p className="text-primary" ><b>LOT SIZE IN Web App Development </b></p>
         <p className="text-primary" ><b>PRODUCTION ORDERS </b></p>
         <p className="text-primary" ><b>PLANNED ORDER </b></p>
         <p className="text-primary" ><b>SALES AND OPERATIONS PLANNING </b></p>
         <p className="text-primary" ><b>WEB APP DEVELOPMENT SOLUTION MANAGER </b></p>
         <p className="text-primary" ><b>CUSTOMIZATION </b></p>
         <p className="text-primary" ><b>LSMW - (Legacy System Migration Workbench) </b></p>
         <p className="text-primary" ><b>INTEGRATION </b></p>
         <p className="text-primary" ><b>OTHER MANUFACTURING PROCESSES IN Web App Development </b></p>
         <p className="text-primary" ><b>PROCESS MANUFACTURING </b></p>
         <p className="text-primary" ><b>REPETITIVE MANUFACTURING </b></p>
         <p className="text-primary" ><b>INTERVIEW ORIENTED TRAINING </b></p>
         <p className="text-primary" ><b>DISCUSSION ON INTERVIEW QUESTIONS </b></p>
         

      </div>
     </div>
   </div>
 </div>
 {/* Section End */}
   </>
  )
}

export default WebDevelopmentTraining