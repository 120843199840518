import React from 'react'

const Transport = () => {
  return (
    <>
    {/* Hero Start */}
   <div className="container-fluid py-5">
    <div className="container">
     <div className="row">
      <div className=" wow fadeInRight" data-wow-delay="0.3s">
        <h5 className="sub-title pe-3">Transport</h5>
          <h4 className="display-6 mb-4">Best Logistics & Transport Management Software</h4>
          <p className="mb-0 myFontS mb-4 ">TransportERP is a Cloud based System that enables efficient and effective management of Transport / Logistic / Warehouse operations. Transport Management, Logistic Management, Warehouse Management, Accounting Management, Vehicle / Fleet Management, Inventory, Tyre Management Diesel Management, MIS Reporting etc. It makes life easier for your Business.</p>
          
          <h3 className="display-7 mb-3 "> <b>How Transport Software helps your Business : </b></h3>
          <p className="mb-0 myFontS mb-4"><b>Increase ROI, Quick decision, Automatic route selection, Track Shipment in real time, Address all operation in one platform, Paperless work, Reduce admin costs &minimize errors, Shipment status, 24/7 Customer support.</b></p>
       
          <h3 className="display-7 mb-3 "> <b>Transport ERP Tools : </b></h3>
          <p className="mb-0 myFontS mb-4"><b>Operation Module , Manage Branches, Client Management, Fleet Management System, Tracking, Secured Data, Reports.</b></p>
       
          <h3 className="display-7 mb-3 "> <b>Our Expertise : </b></h3>
          <p className="mb-0 myFontS"><b>Logistics Management Software , Full Load Transport Software, Part Load Transport Software, Fleet Management Software, Courier Booking Management, Warehouse Management Software.</b></p>
       
        </div>
      </div>
     </div>
  </div>
    <img src="img/retail/sars-manufacturing-textile.png" className="img-fluid w-100 "  alt="Image" />
    {/* Hero End */}
  {/* Section Start */}
  <div className="container-fluid py-4">
    <div className="container py-2">
      <div className="row g-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-5 mb-5 mt-4"> Courier Booking Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-11">
              <div className="mb-5">
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Bookings & Delivery Service Location and Branch creation. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Hub Management. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Rate Card for Normal and On A/c Customers. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Bookings, Dispatch, Delivery, POD update. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> WConsignment tracking. </p>
                
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-fmcg-general-pos.png" className="img-fluid w-100 h-100 "  alt="sars-fmcg-general" />
          </div>
        </div>
      </div>

      <div className="row pt-5" style={{backgroundColor:"#fcfcf7"}}>
       <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-inventry.png" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Fleet Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-9">
              <div className="mb-5">
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Route Planning. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> GPS Tracking of Vehicles. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> View vehicle on map. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Trip Sheet of Driver and Vehicle. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Maintenance Plan. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Performance History. </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-5 pt-3">
        <div className="col-xl-7 wow fadeInRight py-5" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Operation Module </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-9 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Booking & Transshipment Details. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Outgoing, Incoming Manifest. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Run-sheet Preparation & Updating. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> POD update and Doc upload. </p>
                
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-textile-bill.png" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
      </div>
      <div className="row " style={{backgroundColor:"#fcfcf7"}}>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-reporting.png" className="img-fluid w-100 h-100 "  alt="Image" />
            
          </div>
        </div>
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-5 mb-4"> Reporting </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-11">
              <div className="mb-5">
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Incoming, Outgoing Manifest. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Receiving and booking Report. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Sales Report, Target Report. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Run-sheet, Rate Master. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Overall Count Report. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Load Handling Report </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Payment Report, Etc... </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-5 pt-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Accounting & Financial Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-9">
              <div className="mb-5">
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Finance & Accounts Management. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Customer Outstanding Report. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Customer wise invoice. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Doc Wise Invoice. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Bill Submission. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Invoice Tracking. </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-accounting.png" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
      </div>
      <div className="row mt-4 " style={{backgroundColor:"#fcfcf7"}}>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-textile-supply.png" className="img-fluid w-100 h-100 "  alt="Image" />
            
          </div>
        </div>
        <div className="col-xl-7 py-5 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-5 mb-4"> Full Truck Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-11">
              <div className="mb-5">
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Bookings, Dispatch and Delivery. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Vendor Management for payments. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Customer receipts. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Invoicing. </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-5 pt-5 ">
        <div className="col-xl-7 wow fadeInRight py-4" data-wow-delay="0.3s">
          <h2 className="display-5 mb-4"> Shipment Tracking </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-9">
              <div className="mb-5">
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Provide tracking option on website. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Shipment internal tracking. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Shipment online tracking. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Multiple tracking. </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-tracking-delivery.jpg" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
      </div>
      <div className="row mt-4 " style={{backgroundColor:"#fcfcf7"}}>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-transport-barcode.png" className="img-fluid w-100 h-100 "  alt="Image" />
            
          </div>
        </div>
        <div className="col-xl-7 py-5 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-5 mb-4"> BARCODE </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-11">
              <div className="mb-5">
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Barcode for Bookings, Dispatch, Receiving and Delivery. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Tracking of Shortage/Excess. </p>
                <p className="text-primary h6 mb-4"><i className="fa fa-check-circle text-secondary me-2" /> Prevent Fraud by using Barcode. </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  {/* Section End */}
    </>
  )
}

export default Transport