import React from 'react'
import { Link } from 'react-router-dom'

const Contact = () => {
  return (
    <>
    {/* Header Start */}
<div className="container-fluid bg-breadcrumb" style={{width:"100%", height:"200px"}} >
  <div className="container text-center py-0 " >
    <h3 className="text-white display-6 mb-0 wow fadeInDown" data-wow-delay="0.1s">Contact Us</h3>
    <ol className="breadcrumb justify-content-center text-white mb-0 wow fadeInDown" data-wow-delay="0.3s">
      <li className="breadcrumb-item"><Link to="/" className="text-white">Home</Link></li>
      {/* <li className="breadcrumb-item"><Link href="#" className="text-white">Pages</Link></li> */}
      <li className="breadcrumb-item active text-secondary">Contact Us</li>
    </ol>    
  </div>
</div>
{/* Header End */}
      {/* Contact Start */}
<div className="container-fluid contact overflow-hidden py-5">
  <div className="container py-5">
    <div className="row g-5 mb-5">
      <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.1s">
        <div className="sub-style">
          <h5 className="sub-title text-primary pe-3">Quick Contact</h5>
        </div>
        <h1 className="display-6 mb-4">Have Questions? Don't Hesitate to Contact Us</h1>
        <p className="mb-5">Our expertise is in providing integrated ERP Software for Retail, Distribution and Manufacturing to thousands of customers till date, helped them to meet their business goals.</p>
        <div className="d-flex border-bottom mb-4 pb-4">
          <i className="fas fa-map-marked-alt fa-4x text-primary bg-light p-3 rounded" />
          <div className="ps-3">
            <h5>Location</h5>
            <p>1329, First Floor, Sector 13-17, Panipat, Haryana, India.</p>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-xl-6">
            <div className="d-flex">
              <i className="fas fa-tty fa-3x text-primary" />
              <div className="ps-3">
                <h5 className="mb-3">Quick Contact</h5>
                <div className="mb-3">
                  <h6 className="mb-0">Phone:</h6>
                  {/* <a href="#" className="fs-5 text-primary">+91 9034905402</a> */}
                  <a href="#" className="fs-5 text-primary"> 0180-7964198</a>
                </div>
                <div className="mb-3">
                  <h6 className="mb-0">Email:</h6>
                  <a href="#" className="fs-5 text-primary">info@sarserpindia.com</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="d-flex">
              <i className="fas fa-clone fa-3x text-primary" />
              <div className="ps-3">
                <h5 className="mb-3">Opening Hrs</h5>
                <div className="mb-3">
                  <h6 className="mb-0">Monday - Saturday:</h6>
                  <a href="#" className="fs-5 text-primary">09.30 am to 06.30 pm</a>
                </div>
                <div className="mb-3">
                  <h6 className="mb-0">Sunday:</h6>
                  <a href="#" className="fs-5 text-primary">Office Close</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center pt-3">
          <div className="me-4">
            <div className="bg-light d-flex align-items-center justify-content-center" style={{width: 90, height: 90, borderRadius: 10}}><i className="fas fa-share fa-3x text-primary" /></div>
          </div>
          <div className="d-flex">
            <a className="btn btn-secondary border-secondary me-2 p-0" href>facebook <i className="fas fa-chevron-circle-right" /></a>
            <a className="btn btn-secondary border-secondary mx-2 p-0" href>twitter <i className="fas fa-chevron-circle-right" /></a>
            <a className="btn btn-secondary border-secondary mx-2 p-0" href>instagram <i className="fas fa-chevron-circle-right" /></a>
          </div>
        </div>
      </div>
      <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.3">
        <div className="sub-style">
          <h5 className="sub-title text-primary pe-3">Let's Connect</h5>
        </div>
        <h1 className="display-5 mb-4">Send Your Message</h1>
        <p className="mb-3">Our expertise is in providing integrated ERP Software for Retail, Distribution and Manufacturing to thousands of customers till date, helped them to meet their business goals.</p>
        <form>
          <div className="row g-4">
            <div className="col-lg-12 col-xl-6">
              <div className="form-floating">
                <input type="text" className="form-control" id="name" placeholder="Your Name" />
                <label htmlFor="name">Your Name</label>
              </div>
            </div>
            <div className="col-lg-12 col-xl-6">
              <div className="form-floating">
                <input type="email" className="form-control" id="email" placeholder="Your Email" />
                <label htmlFor="email">Your Email</label>
              </div>
            </div>
            <div className="col-lg-12 col-xl-6">
              <div className="form-floating">
                <input type="phone" className="form-control" id="phone" placeholder="Phone" />
                <label htmlFor="phone">Your Phone</label>
              </div>
            </div>
            <div className="col-lg-12 col-xl-6">
              <div className="form-floating">
                <input type="text" className="form-control" id="project" placeholder="Project" />
                <label htmlFor="project">Your Project</label>
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating">
                <input type="text" className="form-control" id="subject" placeholder="Subject" />
                <label htmlFor="subject">Subject</label>
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating">
                <textarea className="form-control" placeholder="Leave a message here" id="message" style={{height: 160}} defaultValue={""} />
                <label htmlFor="message">Message</label>
              </div>
            </div>
            <div className="col-12">
              <button className="btn btn-primary w-100 py-3">Send Message</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    {/* Countries We Offer Start */}
  <div className="container-fluid country overflow-hidden py-5">
    <div className="container">
      <div className="section-title text-center wow fadeInUp" data-wow-delay="0.1s" style={{marginBottom: 70}}>
        <div className="sub-style">
          <h5 className="sub-title text-primary px-3">COUNTRIES WE WORK</h5>
        </div>
        <h1 className="display-5 mb-4">Website &amp; ERP Software Solusions & services following Countries</h1>
        <p className="mb-0">...</p>
      </div>
      <div className="row g-4 text-center">
        <div className="col-lg-6 col-xl-3 mb-5 mb-xl-0 wow fadeInUp" data-wow-delay="0.1s">
          <div className="country-item">
            <div className="rounded overflow-hidden">
              <img src="img/country-1.jpg" className="img-fluid w-100 rounded" alt="Image" />
            </div>
            <div className="country-flag">
              <img src="img/country-flag/srilanka.png" className="img-fluid rounded-circle" alt="Image" />
            </div>
            <div className="country-name">
              <a href="#" className="text-white fs-4">Srilanka</a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-3 mb-5 mb-xl-0 wow fadeInUp" data-wow-delay="0.3s">
          <div className="country-item">
            <div className="rounded overflow-hidden">
              <img src="img/country-2.jpg" className="img-fluid w-100 rounded" alt="Image" />
            </div>
            <div className="country-flag">
              <img src="img/country-flag/india.jpg" className="img-fluid rounded-circle" alt="Image" />
            </div>
            <div className="country-name">
              <a href="#" className="text-white fs-4">india</a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-3 mb-5 mb-xl-0 wow fadeInUp" data-wow-delay="0.5s">
          <div className="country-item">
            <div className="rounded overflow-hidden">
              <img src="img/country-3.jpg" className="img-fluid w-100 rounded" alt="Image" />
            </div>
            <div className="country-flag">
              <img src="img/country-flag/england.png" className="img-fluid rounded-circle" alt="Image" />
            </div>
            <div className="country-name">
              <a href="#" className="text-white fs-4">England</a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-3 mb-5 mb-xl-0 wow fadeInUp" data-wow-delay="0.7s">
          <div className="country-item">
            <div className="rounded overflow-hidden">
              <img src="img/country-4.jpg" className="img-fluid w-100 rounded" alt="Image" />
            </div>
            <div className="country-flag">
              <img src="img/country-flag/dubai.png" className="img-fluid rounded-circle" alt="Image" />
            </div>
            <div className="country-name">
              <a href="#" className="text-white fs-4">Dubai</a>
            </div>
          </div>
        </div>
        <div className="col-12">
          <a className="btn btn-primary border-secondary rounded-pill py-3 px-5 wow fadeInUp" data-wow-delay="0.1s" href="#">More Countries</a>
        </div>
      </div>
    {/* Countries We Offer End */}
        {/* <div className="col-12 pt-5 wow zoomIn" data-wow-delay="0.1s">
          <div className="rounded h-100">
            <iframe className="rounded w-100" style={{height: 500}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387191.33750346623!2d-73.97968099999999!3d40.6974881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1694259649153!5m2!1sen!2sbd" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
          </div>
        </div> */}
    </div>
  </div>
  
  </div>
</div>
{/* Contact End */}

    </>
  )
}

export default Contact