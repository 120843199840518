import About from "./Components/About";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import { Routes, Route } from 'react-router-dom'
import Navbar from "./Components/Navbar";
import Visit from "./Components/Visit";
import PharmaHeathCare from "./Components/Retails/PharmaHeathCare";
import AF_Textile from "./Components/Retails/AF_Textile";
import FMCG_GeneralStore from "./Components/Retails/FMCG_GeneralStore";
import Electrical_HomeAppliances from "./Components/Retails/Electrical_HomeAppliances";
import LF_HomeDecorFurniture from "./Components/Retails/LF_HomeDecorFurniture";
import FB_Restaurant from "./Components/Retails/FB_Restaurant";
import WS_ZymFitnessCenter from "./Components/Retails/WS_ZymFitnessCenter";
import LF_FashionJewellery from "./Components/Retails/LF_FashionJewellery";
import MERN from "./Components/Courses/MERN";
import MEAN from "./Components/Courses/MEAN";
import ReactCourse from "./Components/Courses/ReactCourse";
import AngularCourse from "./Components/Courses/AngularCourse";
import Oop_DSAConcept from "./Components/Courses/Oop_DSAConcept";
import B_DigitalMarketing from "./Components/Courses/B_DigitalMarketing";
import A_DigitalMarketing from "./Components/Courses/A_DigitalMarketing";
import PhpFullStack from "./Components/Courses/PhpFullStack";
import PhpExpert from "./Components/Courses/PhpExpert";
import ERP_PP from "./Components/Courses/ERP_PP";
import AndroidDevelopment from "./Components/Courses/MobileAppReactNative";
import MongodbCourse from "./Components/Courses/MongodbCourse";
import NodeJsCourse from "./Components/Courses/NodeJsCourse";
import WebDevelopmentTraining from "./Components/Courses/WebDevelopmentTraining";
import MobileAppReactNative from "./Components/Courses/MobileAppReactNative";
import OurCustomer from "./Components/Company/OurCustomer";
import Transport from "./Components/Distribution/Transport";


function App() {
  return (
    <>
    <Navbar />
    <Routes>
     <Route path="/" element={<Home />} />
     <Route path="/contact" element={<Contact />} />
     <Route path="/about" element={<About />} />
     <Route path="/visit" element={<Visit />} />
     <Route path="/pharmahealthcare" element={<PharmaHeathCare />} />
     <Route path="/af-textile" element={<AF_Textile />} />
     <Route path="/fmcg-generalstore" element={<FMCG_GeneralStore />} />
     <Route path="/electrical-home-appliances" element={<Electrical_HomeAppliances />} />
     <Route path="/lf-home-decor-furniture" element={<LF_HomeDecorFurniture />} />
     <Route path="/fb-restaurant" element={<FB_Restaurant />} />
     <Route path="/wh-gymfitnesscenter" element={<WS_ZymFitnessCenter />} />
     <Route path="/lf-fashionjewellery" element={<LF_FashionJewellery />} />
     
     <Route path="/mernstack" element={<MERN />} />
     <Route path="/meanstack" element={<MEAN />} />
     <Route path="/reactcourse" element={<ReactCourse />} />
     <Route path="/angularcourse" element={<AngularCourse />} />
     <Route path="/oop-dsaconcept" element={<Oop_DSAConcept />} />
     <Route path="/b-digitalmarketing" element={<B_DigitalMarketing />} />
     <Route path="/a-digitalmarketing" element={<A_DigitalMarketing />} />
     <Route path="/phpfullstack" element={<PhpFullStack />} />
     <Route path="/phpexpert" element={<PhpExpert />} />
     <Route path="/erp-pp-software" element={<ERP_PP />} />
     <Route path="/mobileapp-reactnative" element={<MobileAppReactNative />} />
     <Route path="/mongodb-course" element={<MongodbCourse />} />
     <Route path="/nodejs-course" element={<NodeJsCourse />} />
     <Route path="/webdevelopment-training" element={<WebDevelopmentTraining />} />

     <Route path="/ourcustomer" element={<OurCustomer />} />
     <Route path="/transport" element={<Transport />} />
     
    
     
    </Routes>
    <Footer />
    </>
  );
}

export default App;
