import React from 'react'

const Electrical_HomeAppliances = () => {
  return (
    <>
    <div className="container-fluid py-5">
    <div className="container">
      <div className="row">
    <div className=" wow fadeInRight" data-wow-delay="0.3s">
        <h5 className="sub-title pe-3">Electrical & Electronics Home Appliances</h5>
          <h4 className="display-6 mb-4">The Most Advanced ERP Software for Electrical & Electronics Retail Store </h4>
          <p className="mb-0 myFontS">The Best ERP Solution for Home Appliances Retail Stores with features like POS, IMEI/serial no. inventory, home delivery, 
            complaint management, orders, accounting, GST, single/multi stores, reports on mobile and much more.</p>
    </div>
    </div>
    </div>
    </div>
    <img src="img/retail/sars-elec-appliances.png" className="img-fluid w-100 "  alt="Image" />
  {/* About Start */}
  <div className="container-fluid py-5">
    <div className="container py-2">
      <div className="row g-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> POS - Point of Sale </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-9 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Advanced and fully configurable POS system </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Multiple Views </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Touch/Mobile POS </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Flexible Schemes and promotions </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Cashier/Shift System </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Multiple Payment Modes </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Integrated with PayTM, PayU, Mobikwik, Pine Lab, Jio Money </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Home Delivery app </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Scan barcodes of Serial Numbers for billing </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Warranty/Guarantee management </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Complaint management </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Financer management and payment tracking from financers </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Dispatch management, Order at retail store and dispatch from warehouse </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Price drop management </p>
                
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-fmcg-general-pos.png" className="img-fluid w-100 h-100 "  alt="sars-fmcg-general" />
          </div>
        </div>
      </div>

      <div className="row pt-5" style={{backgroundColor:"#fcfcf7"}}>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-inventry.png" className="img-fluid w-100 h-100 "  alt="Image" />
           
          </div>
        </div>
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Home Delivery Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-11">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Home Delivery app </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Allocate home delivery bills/invoices to delivery agents </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Navigation for delivery through Google Maps </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Delivery validation/confirmation using OTP or signature or both </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Payment collection upon delivery </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Automatic payment settlement synchronization in the software upon delivery </p>
                
              </div>
              
            </div>
          </div>
        </div>

      </div>

      <div className="row g-5 pt-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Inventory Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-11">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Serial Number/IMEI number wise stock </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Import Serial Numbers from Excel </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Import Item Masters from Excel </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Fully customizable Barcode Generation </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Dead/Slow Moving Stock Reporting and Management </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Bulk updates of rates through excel </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Tight control of returned items </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Import purchase from external file (excel/text) </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> eCommerce integration (Unicommerce, Vinculum, eShipz etc) </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Service Centre or Repair center inventory management of spare Items and tools. </p>
                
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded mt-3">
            <img src="img/retail/sars-inventry.png" className="img-fluid w-100 h-100 "  alt="Image" />
            
            {/* <img src="img/about-3.jpg" className="img-fluid w-100 border-bottom border-5 border-primary" style={{borderTopRightRadius: 300, borderTopLeftRadius: 300}} alt="Image" /> */}
          </div>
        </div>
      </div>

      <div className="row pt-5" style={{backgroundColor:"#fcfcf7"}}>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-loyalty.png" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Loyalty & Coupon Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Inbuilt Loyalty System for Points Accrual and Redemption with customizable configuration </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Brand/Category wise membership packages </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Discount Coupons and Gift Vouchers </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> OTP based Points and Coupon Redemption </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Manual Discount Entry approval through Mobile App or OTP </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Customized Feedback and Surveys </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Instant Feedback at POS </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Integrated with third party Loyalty Programs </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-5 pt-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Reporting </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-9">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Pre-Configured reporting template </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Serial Number/IMEI number wise reporting </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Mobile Reporting App </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Email reports with Auto Report Mailer </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Power Reports and Dashboards </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Export to Excel/HTML/PDF </p>
                
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-reporting.png" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
      </div>

      <div className="row pt-5" style={{backgroundColor:"#fcfcf7"}}>
        <div className="col-xl-5 mt-4 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
          <img src="img/retail/sars-accounting.png" className="img-fluid w-100 h-100 "  alt="Image" />

          </div>
        </div>
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Accounting & Financial Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-11">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Simple and Configurable Accounting Module </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Journal Entries, Cash/Bank Vouchers </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Bills Receivables/Payables </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Cost Centers, Cheque Printing </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Balance Sheet, P&L, Ratio Analysis </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> e-File GST Returns, Generate E-Way bills, TDS/TCS Module </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Journal Purchase/Debit Note Entry HSN wise. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Journal Sale/Credit Notes Entry HSN wise </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> GST Summary and Auto-Entry of GST payable with Journal Entry </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Post Date Cheques Entry (Receipts/Payments) </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  {/* About End */}
    </>
  )
}

export default Electrical_HomeAppliances