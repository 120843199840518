import React from 'react'
import { Link } from 'react-router-dom'

const OurCustomer = () => {
  return (
    <>
     {/* Hero Start */}
<div className="container-fluid bg-breadcrumb" style={{width:"100%", height:"200px"}} >
 <div className="container text-center py-0 " >
   <h3 className="text-white display-6 mb-0 wow fadeInDown" data-wow-delay="0.1s">Our Customers</h3>
   <ol className="breadcrumb justify-content-center text-white mb-0 wow fadeInDown" data-wow-delay="0.3s">
     <li className="breadcrumb-item"><Link to="/" className="text-white">Home</Link></li>
     {/* <li className="breadcrumb-item"><Link href="#" className="text-white">Pages</Link></li> */}
     <li className="breadcrumb-item active text-secondary">Our Customers</li>
   </ol>
 </div>
</div>
{/* Hero End */}
{/* Training Start */}
<div className="container-fluid training bg-light overflow-hidden ">
  <div className="container py-5">
    <div className="section-title text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="sub-style">
        <h5 className="sub-title text-primary px-3">CHECK OUR Customers</h5>
      </div>
      <h1 className="display-5 mb-4 py-3"> Our Esteemed Clients.</h1>
      {/* <p className="mb-0"> Repellat! </p> */}
    </div>
    <div className="row g-4">
      <div className="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
        <div className="training-item">
          <div className="training-inner">
            <img src="img/ourCustomer/1.png" className="img-fluid w-100 rounded imgH" alt="Image" />
            <div className="training-title-name">
              <a href="#" className="h4 text-white mb-0">DreamUp</a>
              <a href="#" className="h4 text-white mb-0">Immigration</a>
            </div>
          </div>
          <div className="training-content bg-secondary rounded-bottom p-4">
            <Link to=""><h4 className="text-white">DreamUp Immigration</h4></Link>
            <p className="text-white-50">...</p>
            {/* <a className="btn btn-secondary rounded-pill text-white p-0" href="#">Read More <i className="fa fa-arrow-right" /></a> */}
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
        <div className="training-item">
          <div className="training-inner">
            <img src="img/ourCustomer/2.png" className="img-fluid w-100 rounded imgH" alt="Image" />
            <div className="training-title-name">
              <a href="#" className="h4 text-white mb-0">Sunflag</a>
              <a href="#" className="h4 text-white mb-0">Global</a>
            </div>
          </div>
          <div className="training-content bg-secondary rounded-bottom p-4">
            <a href="#"><h4 className="text-white">Sunflag Global</h4></a>
            <p className="text-white-50">...</p>
            {/* <a className="btn btn-secondary rounded-pill text-white p-0" href="#">Read More <i className="fa fa-arrow-right" /></a> */}
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
        <div className="training-item">
          <div className="training-inner">
            <img src="img/ourCustomer/3.png" className="img-fluid w-100 rounded imgH" alt="Image" />
            <div className="training-title-name">
              <a href="#" className="h4 text-white mb-0">Max Plus</a>
              <a href="#" className="h4 text-white mb-0">Hospital</a>
            </div>
          </div>
          <div className="training-content bg-secondary rounded-bottom p-4">
            <a href="#"><h4 className="text-white">Max Plus</h4></a>
            <p className="text-white-50">...</p>
            {/* <a className="btn btn-secondary rounded-pill text-white p-0" href="#">Read More <i className="fa fa-arrow-right" /></a> */}
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
        <div className="training-item">
          <div className="training-inner">
            <img src="img/ourCustomer/4.png" className="img-fluid w-100 rounded imgH" alt="Image" />
            <div className="training-title-name">
              <a href="#" className="h4 text-white mb-0">Primus</a>
              <a href="#" className="h4 text-white mb-0">Hospital</a>
            </div>
          </div>
          <div className="training-content bg-secondary rounded-bottom p-4">
            <a href="#"><h4 className="text-white">Primus</h4></a>
            <p className="text-white-50">...</p>
            {/* <a className="btn btn-secondary rounded-pill text-white p-0" href="#">Read More <i className="fa fa-arrow-right" /></a> */}
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
        <div className="training-item">
          <div className="training-inner">
            <img src="img/ourCustomer/5.png" className="img-fluid w-100 rounded imgH" alt="Image" />
            <div className="training-title-name">
              <a href="#" className="h4 text-white mb-0">Taneja</a>
              <a href="#" className="h4 text-white mb-0">Hospital</a>
            </div>
          </div>
          <div className="training-content bg-secondary rounded-bottom p-4">
            <Link to=""><h4 className="text-white">Taneja </h4></Link>
            <p className="text-white-50">...</p>
            {/* <a className="btn btn-secondary rounded-pill text-white p-0" href="#">Read More <i className="fa fa-arrow-right" /></a> */}
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
        <div className="training-item">
          <div className="training-inner">
            <img src="img/ourCustomer/6.png" className="img-fluid w-100 rounded imgH" alt="Image" />
            <div className="training-title-name">
              <a href="#" className="h4 text-white mb-0">Prime</a>
              <a href="#" className="h4 text-white mb-0">Hospital</a>
            </div>
          </div>
          <div className="training-content bg-secondary rounded-bottom p-4">
            <a href="#"><h4 className="text-white">Prim</h4></a>
            <p className="text-white-50">...</p>
            {/* <a className="btn btn-secondary rounded-pill text-white p-0" href="#">Read More <i className="fa fa-arrow-right" /></a> */}
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
        <div className="training-item">
          <div className="training-inner">
            <img src="img/ourCustomer/7.png" className="img-fluid w-100 rounded imgH" alt="Image" />
            <div className="training-title-name">
              <a href="#" className="h4 text-white mb-0">Ujjivan</a>
              <a href="#" className="h4 text-white mb-0">Hospital</a>
            </div>
          </div>
          <div className="training-content bg-secondary rounded-bottom p-4">
            <a href="#"><h4 className="text-white">Ujjivan</h4></a>
            <p className="text-white-50">...</p>
            {/* <a className="btn btn-secondary rounded-pill text-white p-0" href="#">Read More <i className="fa fa-arrow-right" /></a> */}
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
        <div className="training-item">
          <div className="training-inner">
            <img src="img/ourCustomer/8.png" className="img-fluid w-100 rounded imgH" alt="Image" />
            <div className="training-title-name">
              <a href="#" className="h4 text-white mb-0">Ever Bharat</a>
              <a href="#" className="h4 text-white mb-0">Water</a>
            </div>
          </div>
          <div className="training-content bg-secondary rounded-bottom p-4">
            <a href="#"><h4 className="text-white">Ever Bharat</h4></a>
            <p className="text-white-50">...</p>
            {/* <a className="btn btn-secondary rounded-pill text-white p-0" href="#">Read More <i className="fa fa-arrow-right" /></a> */}
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
        <div className="training-item">
          <div className="training-inner">
            <img src="img/training-1.jpg" className="img-fluid w-100 rounded imgH" alt="Image" />
            <div className="training-title-name">
              <a href="#" className="h4 text-white mb-0">Galaxy</a>
              <a href="#" className="h4 text-white mb-0">Hospital</a>
            </div>
          </div>
          <div className="training-content bg-secondary rounded-bottom p-4">
            <Link to=""><h4 className="text-white">Galaxy</h4></Link>
            <p className="text-white-50">...</p>
            {/* <a className="btn btn-secondary rounded-pill text-white p-0" href="#">Read More <i className="fa fa-arrow-right" /></a> */}
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
        <div className="training-item">
          <div className="training-inner">
            <img src="img/training-2.jpg" className="img-fluid w-100 rounded imgH" alt="Image" />
            <div className="training-title-name">
              <a href="#" className="h4 text-white mb-0">Sumit</a>
              <a href="#" className="h4 text-white mb-0">Hospital</a>
            </div>
          </div>
          <div className="training-content bg-secondary rounded-bottom p-4">
            <a href="#"><h4 className="text-white">Sumit</h4></a>
            <p className="text-white-50">...</p>
            {/* <a className="btn btn-secondary rounded-pill text-white p-0" href="#">Read More <i className="fa fa-arrow-right" /></a> */}
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
        <div className="training-item">
          <div className="training-inner">
            <img src="img/training-3.jpg" className="img-fluid w-100 rounded imgH" alt="Image" />
            <div className="training-title-name">
              <a href="#" className="h4 text-white mb-0">G.C. Gupta</a>
              <a href="#" className="h4 text-white mb-0">Hospital</a>
            </div>
          </div>
          <div className="training-content bg-secondary rounded-bottom p-4">
            <a href="#"><h4 className="text-white">G.C. Gupta</h4></a>
            <p className="text-white-50">...</p>
            {/* <a className="btn btn-secondary rounded-pill text-white p-0" href="#">Read More <i className="fa fa-arrow-right" /></a> */}
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
        <div className="training-item">
          <div className="training-inner">
            <img src="img/training-4.jpg" className="img-fluid w-100 rounded imgH" alt="Image" />
            <div className="training-title-name">
              <a href="#" className="h4 text-white mb-0">Osca</a>
              <a href="#" className="h4 text-white mb-0">Hospital</a>
            </div>
          </div>
          <div className="training-content bg-secondary rounded-bottom p-4">
            <a href="#"><h4 className="text-white">Osca</h4></a>
            <p className="text-white-50">...</p>
            {/* <a className="btn btn-secondary rounded-pill text-white p-0" href="#">Read More <i className="fa fa-arrow-right" /></a> */}
          </div>
        </div>
      </div>

      <div className="col-12 text-center">
        <a className="btn btn-primary border-secondary rounded-pill py-3 px-5 wow fadeInUp" data-wow-delay="0.1s" href="#">View More</a>
      </div>
    </div>
  </div>
</div>
{/* Training End */}

    </>
  )
}

export default OurCustomer