import React from 'react'

const FB_Restaurant = () => {
  return (
    <>
    <div className="container-fluid py-5">
      <div className="container">
      <div className="row">
        <div className=" wow fadeInRight" data-wow-delay="0.3s">
         <h5 className="sub-title pe-3">Food & Beverages Restaurant</h5>
          <h4 className="display-6 mb-4">Fully Configurable and Flexible ERP Software for Resturants </h4>
          <p className="mb-0 myFontS">Run your Restaurant with the most comprehensive ERP Software with features like 
            Touch/Mobile POS, KOT, table management, recipe management, combo meals/addon items, home delivery, CRM, 
            accounting, GST, reports on mobile and much more.</p>
        </div>
       </div>
      </div>
    </div>
    <img src="img/retail/sars-elec-appliances.png" className="img-fluid w-100 "  alt="Image" />
  {/* About Start */}
  <div className="container-fluid py-5">
    <div className="container py-2">
      <div className="row g-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> POS - Point of Sale </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-9 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Advanced and fully configurable POS system. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Linked with kitchen for generation of KOT. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> KOT display both at bar/ kitchen as well as waiter side. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Flexible Schemes and promotions. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Multiple outlet management at Food Court. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Combo meal/addon Items management of menu items. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> GST Billing for Food and VAT Billing for Liquor. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Single or different menu across multiple billing points. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Touch/Mobile POS, Cashier/Shift System. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Flexible Schemes and promotions. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Integrated with PayTM, PayU, Mobikwik, Pine Lab, Jio Money. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Payment through cash card, pre-paid cards, magnetic cards, or advance payment receipt at outlet. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Refund of amount with security amount in case of cash card, pre-paid, smart card, magnetic card. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Multiple tendering through Cash, Credit Card, Gift voucher, Loyalty Card, Pre-Paid Card etc. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Multiple levels of security and authorizations. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Table Management for restaurants. </p>
                
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-fmcg-general-pos.png" className="img-fluid w-100 h-100 "  alt="sars-fmcg-general" />
          </div>
        </div>
      </div>

      <div className="row mt-4 pt-5" style={{backgroundColor:"#fcfcf7"}}>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-restaurant.png" className="img-fluid w-100 h-100 "  alt="Image" />
           
          </div>
        </div>
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Home Delivery and Take Away Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Identify Guests based on phone number and other fields. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Home Delivery app. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Allocate home delivery bills/invoices to delivery agents. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Navigation for delivery through Google Maps. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Delivery validation/confirmation using OTP or signature or both. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Payment collection upon delivery. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Automatic payment settlement synchronization in the software upon delivery. </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-5 pt-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Inventory Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-9 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Service or product-based inventory. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Recipe management with track of over-consumption across different menu items. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Handling of multiple units of measure for packaging at purchase and sales. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Recipe targets and planning. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Import Item Masters from Excel. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Fully customizable Barcode Generation. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Dead/Slow Moving Stock Reporting and Management. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Bulk updates of rates through excel. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Tight control of returned items. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Import purchase from external file (excel/text) </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Material requisition, Purchase orders, Rate contracts etc. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Stock inward, Outward, Purchase, GRN, Expiry, Wastage, Damage and Return. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Stock Auditing, closing stock status as on date and inventory analysis. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Reorder level, minimum stock, and auto replenishment. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Assembling/De-Assembling for gift packing of items on festivals. </p>
                
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light mt-5 rounded">
            <img src="img/retail/sars-inventry.png" className="img-fluid w-100 h-100 "  alt="Image" />
            
          </div>
        </div>
      </div>

      <div className="row pt-5" style={{backgroundColor:"#fcfcf7"}}>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-loyalty.png" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Loyalty & Coupon Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Inbuilt Loyalty System for Points Accrual and Redemption with customizable configuration. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Brand/Category wise membership packages. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Discount Coupons and Gift Vouchers. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> OTP based Points and Coupon Redemption. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Manual Discount Entry approval through Mobile App or OTP. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Customized Feedback and Surveys. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Instant Feedback at POS. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Integrated with third party Loyalty Programs. </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-5 pt-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Reporting </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Pre-Configured reporting template. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Mobile Reporting App. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Email reports with Auto Report Mailer. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Power Reports and Dashboards. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Export to Excel/HTML/PDF. </p>
                
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-reporting.png" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
      </div>

      <div className="row pt-5" style={{backgroundColor:"#fcfcf7"}}>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
          <img src="img/retail/sars-accounting.png" className="img-fluid w-100 h-100 "  alt="Image" />

          </div>
        </div>
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Accounting & Financial Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Simple and Configurable Accounting Module. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Journal Entries, Cash/Bank Vouchers. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Bills Receivables/Payables. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Cost Centers, TDS/TCS Module. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Balance Sheet, P&L, Ratio Analysis. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> e-File GST Returns, Generate E-Way bills, Cheque Printing. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Journal Purchase/Debit Note Entry HSN wise. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Journal Sale/Credit Notes Entry HSN wise. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> GST Summary and Auto-Entry of GST payable with Journal Entry. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Post Date Cheques Entry (Receipts/Payments) </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  {/* About End */}
    </>
  )
}

export default FB_Restaurant