import React from 'react'

const FMCG_GeneralStore = () => {
  return (
    <>
    <div className="container-fluid py-5">
    <div className="container">
      <div className="row">
    <div className=" wow fadeInRight" data-wow-delay="0.3s">
        <h5 className="sub-title pe-3">FMCG - General Store</h5>
          <h4 className="display-6 mb-4">Fully Configurable and Flexible ERP Software for General Retail Stores </h4>
          <p className="mb-0 myFontS">Streamline your General Retail Store operations using the best ERP software with complete POS features, 
            advanced inventory management, CRM & Loyalty, Schemes & Discounts, Weighing Scale Integration, Accounting, GST, flexible and configurable 
            reporting on mobile and much more.</p>
    </div>
    </div>
    </div>
    </div>
    <img src="img/retail/sars-key-textile.png" className="img-fluid w-100 "  alt="Image" />
  {/* Section Start */}
  <div className="container-fluid py-5">
    <div className="container py-2">
      <div className="row g-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> POS - Point of Sale </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-11">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Advanced and fully configurable POS system </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Multiple Views </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Touch/Mobile POS </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Flexible Schemes and promotions </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Weighing Scale Integration </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Conversion factors (Buy in cases/Dozens etc. and sell in pcs) </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Mobile app for online ordering </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Home Delivery app </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Cashier/Shift System </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Multiple Payment Modes </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Integrated with PayTM, PayU, Mobikwik, Pine Lab, Jio Money </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Expiry Prompt </p>
                
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-fmcg-general-pos.png" className="img-fluid w-100 h-100 "  alt="sars-fmcg-general" />
          </div>
        </div>
      </div>

      <div className="row pt-5" style={{backgroundColor:"#fcfcf7"}}>
       <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-inventry.png" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Inventory Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-9">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Batch Wise/MRP wise stock </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Import Item Masters from Excel </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Fully customizable Barcode Generation </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Dead/Slow Moving Stock Reporting and Management </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Bulk updates of rates through excel </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Tight control of returned items </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Import purchase from external file (excel/text) </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> eCommerce integration (unicommerce, browntape) </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Pre-Configured templates with 16000+ SKUs already added </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-5 pt-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Loyalty & Coupon Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-9 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Inbuilt Loyalty System for Points Accrual and Redemption with customizable configuration </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Brand/Category wise membership packages </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Discount Coupons and Gift Vouchers </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> OTP based Points and Coupon Redemption </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Manual Discount Entry approval through Mobile App or OTP </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Customized Feedback and Surveys </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Instant Feedback at POS </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Integrated with third party Loyalty Programs </p>
                
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-loyalty.png" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
      </div>
      <div className="row pt-5" style={{backgroundColor:"#fcfcf7"}}>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-reporting.png" className="img-fluid w-100 h-100 "  alt="Image" />
            
            {/* <img src="img/about-3.jpg" className="img-fluid w-100 border-bottom border-5 border-primary" style={{borderTopRightRadius: 300, borderTopLeftRadius: 300}} alt="Image" /> */}
          </div>
        </div>
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Reporting </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-11">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Pre-Configured reporting template </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Configurable reports and dashboards </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Mobile Reporting App </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Email reports with Auto Report Mailer </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Power Reports and Dashboards </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Export to Excel/HTML/PDF </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Expired/Near Expiry Stock List (Supplier wise also available.) </p>
                
              </div>
              {/* <div className="d-flex flex-wrap">
                <div id="phone-tada" className="d-flex align-items-center justify-content-center me-4">
                  <a href className="position-relative wow tada" data-wow-delay=".9s">
                    <i className="fa fa-phone-alt text-primary fa-3x" />
                    <div className="position-absolute" style={{top: 0, left: 25}}>
                      <span><i className="fa fa-comment-dots text-secondary" /></span>
                    </div>
                  </a>
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <span className="text-primary">Have any questions?</span>
                  <span className="text-secondary fw-bold fs-5" style={{letterSpacing: 2}}>Free: +91 9034905402</span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="row g-5 pt-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
         
          <h2 className="display-6 mb-4"> Accounting & Financial Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-9">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Simple and Configurable Accounting Module </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> General Entries, Cash/Bank Vouchers </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Bills Receivables/Payables </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Cost Centers </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Balance Sheet, P&L, Ratio Analysis </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> e-File GST Returns </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Generate E-Way bills </p>
                
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-accounting.png" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Section End */}
    </>
  )
}

export default FMCG_GeneralStore