import React from 'react'


const Home = () => {
  return (
    <>
<div>
  {/* Nav Start */}
  
  {/* Nav End */}
  {/* Carousel Start */}
  <div>
  {/* <div className="carousel-header">
    <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
      <ol className="carousel-indicators">
        <li data-bs-target="#carouselId" data-bs-slide-to={0} className="active" />
        <li data-bs-target="#carouselId" data-bs-slide-to={1} />
      </ol>
      <div className="carousel-inner" role="listbox">
        <div className="carousel-item active">
          <img src="img/carousel-1.jpg" className="img-fluid" alt="Image" />
          <div className="carousel-caption">
            <div className="text-center p-4" style={{maxWidth: 900}}>
              <h4 className="text-white text-uppercase fw-bold mb-3 mb-md-4 wow fadeInUp" data-wow-delay="0.1s">Solution For All Type Of ERP Software</h4>
              <h1 className="display-1 text-capitalize text-white mb-3 mb-md-4 wow fadeInUp" data-wow-delay="0.3s">SARS ERP IT Company</h1>
              <p className="text-white mb-4 mb-md-5 fs-5 wow fadeInUp" data-wow-delay="0.5s">Best fitting and sustainable infrastructure for your business. </p>
              <a className="btn btn-primary border-secondary rounded-pill text-white py-3 px-5 wow fadeInUp" data-wow-delay="0.7s" href="#">More Details</a>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img src="img/carousel-2.jpg" className="img-fluid" alt="Image" />
          <div className="carousel-caption">
            <div className="text-center p-4" style={{maxWidth: 900}}>
              <h5 className="text-white text-uppercase fw-bold mb-3 mb-md-4 wow fadeInUp" data-wow-delay="0.1s">Solution For All Type Of Websites</h5>
              <h1 className="display-1 text-capitalize text-white mb-3 mb-md-4 wow fadeInUp" data-wow-delay="0.3s">SARS ERP IT Company</h1>
              <p className="text-white mb-4 mb-md-5 fs-5 wow fadeInUp" data-wow-delay="0.5s">Best fitting and sustainable infrastructure for your business. </p>
              <a className="btn btn-primary border-secondary rounded-pill text-white py-3 px-5 wow fadeInUp" data-wow-delay="0.7s" href="#">More Details</a>
            </div>
          </div>
        </div>
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
        <span className="carousel-control-prev-icon bg-secondary wow fadeInLeft" data-wow-delay="0.2s" aria-hidden="false" />
        <span className="visually-hidden-focusable">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
        <span className="carousel-control-next-icon bg-secondary wow fadeInRight" data-wow-delay="0.2s" aria-hidden="false" />
        <span className="visually-hidden-focusable">Next</span>
      </button>
    </div>
  </div> */}
  </div>
  <img src='/img/sars-banner.gif' alt='sars~gip' style={{width:"100%", height:"20%"}}></img>
  {/* Carousel End */}
  {/* Modal Search Start */}
  {/* <div className="modal fade" id="searchModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-fullscreen">
      <div className="modal-content rounded-0">
        <div className="modal-header">
          <h4 className="modal-title text-secondary mb-0" id="exampleModalLabel">Search by keyword</h4>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body d-flex align-items-center">
          <div className="input-group w-75 mx-auto d-flex">
            <input type="search" className="form-control p-3" placeholder="keywords" aria-describedby="search-icon-1" />
            <span id="search-icon-1" className="input-group-text p-3"><i className="fa fa-search" /></span>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  {/* Modal Search End */}
  {/* About Start */}
  <div className="container-fluid py-5">
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/training/2.png" className="img-fluid w-100 "  alt="Image" />
            {/* <img src="img/about-3.jpg" className="img-fluid w-100 border-bottom border-5 border-primary" style={{borderTopRightRadius: 300, borderTopLeftRadius: 300}} alt="Image" /> */}
          </div>
        </div>
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h5 className="sub-title pe-3">About the company</h5>
          <h1 className="display-5 mb-4">SARS ERP </h1>
          <p className="mb-4">Our expertise is in providing integrated ERP Software for Retail, Distribution 
            and Manufacturing to thousands of customers till date, helped them to meet their 
            business goals.</p>
          <div className="row gy-4 align-items-center">
            <div className="col-12 col-sm-6 d-flex align-items-center">
              <i className="fas fa-map-marked-alt fa-3x text-secondary" />
              <h5 className="ms-4">Best ERP Software</h5>
            </div>
            <div className="col-12 col-sm-6 d-flex align-items-center">
              <i className="fas fa-passport fa-3x text-secondary" />
              <h5 className="ms-4">Websites</h5>
            </div>
            <div className="col-4 col-md-3">
              <div className="bg-light text-center rounded p-3">
                <div className="mb-2">
                  <i className="fas fa-ticket-alt fa-4x text-primary" />
                </div>
                <h1 className="display-5 fw-bold mb-2">10</h1>
                <p className="text-muted mb-0">Years of Experience</p>
              </div>
            </div>
            <div className="col-8 col-md-9">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Offer 100 % Genuine Assistance</p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> It's Faster &amp; Reliable Execution</p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Expert Developer Team</p>
              </div>
              <div className="d-flex flex-wrap">
                <div id="phone-tada" className="d-flex align-items-center justify-content-center me-4">
                  <a href className="position-relative wow tada" data-wow-delay=".9s">
                    <i className="fa fa-phone-alt text-primary fa-3x" />
                    <div className="position-absolute" style={{top: 0, left: 25}}>
                      <span><i className="fa fa-comment-dots text-secondary" /></span>
                    </div>
                  </a>
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <span className="text-primary">Have any questions?</span>
                  <span className="text-secondary fw-bold fs-5" style={{letterSpacing: 2}}>Free: +91 9034905402</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* About End */}
  {/* Counter Facts Start */}
  <div className="container-fluid counter-facts py-5">
    <div className="container py-5">
      <div className="row g-4">
        <div className="col-12 col-sm-6 col-md-6 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
          <div className="counter">
            <div className="counter-icon">
              <i className="fas fa-passport" />
            </div>
            <div className="counter-content">
              <h3>ERP Categories</h3>
              <div className="d-flex align-items-center justify-content-center">
                <span className="counter-value" data-toggle="counter-up">20</span>
                <h4 className="text-secondary mb-0" style={{fontWeight: 600, fontSize: 25}}>+</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
          <div className="counter">
            <div className="counter-icon">
              <i className="fas fa-users" />
            </div>
            <div className="counter-content">
              <h3>Team Members</h3>
              <div className="d-flex align-items-center justify-content-center">
                <span className="counter-value" data-toggle="counter-up">100</span>
                <h4 className="text-secondary mb-0" style={{fontWeight: 600, fontSize: 25}}>+</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
          <div className="counter">
            <div className="counter-icon">
              <i className="fas fa-user-check" />
            </div>
            <div className="counter-content">
              <h3>Software Process</h3>
              <div className="d-flex align-items-center justify-content-center">
                <span className="counter-value" data-toggle="counter-up">4.9</span>
                <h4 className="text-secondary mb-0" style={{fontWeight: 600, fontSize: 25}}>K</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
          <div className="counter">
            <div className="counter-icon">
              <i className="fas fa-handshake" />
            </div>
            <div className="counter-content">
              <h3>Solution Rates</h3>
              <div className="d-flex align-items-center justify-content-center">
                <span className="counter-value" data-toggle="counter-up">100</span>
                <h4 className="text-secondary mb-0" style={{fontWeight: 600, fontSize: 25}}>%</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Counter Facts End */}
  {/* Services Start */}
  <div className="container-fluid service overflow-hidden pt-5">
    <div className="container py-5">
      <div className="section-title text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="sub-style">
          <h5 className="sub-title text-primary px-3">ERP Categories</h5>
        </div>
        <h1 className="display-5 mb-4">SARS ERP</h1>
        <p className="mb-0">The ERP system meaning encompasses the centralization of critical business processes, making it easier to manage and analyze data in real time. This holistic approach to managing a company's resources leads to increased efficiency, cost savings, and improved decision-making. The ERP system definition highlights the essential role it plays in connecting different departments and information systems within an organization.</p>
          
      </div>
      <div className="row g-4">
        <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.1s">
          <div className="service-item">
            <div className="service-inner">
              <div className="service-img">
                <img src="img/sarserp/1.png" className="img-fluid w-100 rounded" alt="Image" />
              </div>
              <div className="service-title">
                <div className="service-title-name">
                  <div className="bg-primary text-center rounded p-3 mx-5 mb-4">
                    <a href="#" className="h4 text-white mb-0">Apparel ERP</a>
                  </div>
                  <a className="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4" href="#">Explore More</a>
                </div>
                <div className="service-content pb-4">
                  <a href="#"><h4 className="text-white mb-4 py-3">Apparel ERP</h4></a>
                  <div className="px-4">
                    <p className="mb-4">...</p>
                    <a className="btn btn-primary border-secondary rounded-pill py-3 px-5" href="#">Explore More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.3s">
          <div className="service-item">
            <div className="service-inner">
              <div className="service-img">
                <img src="img/sarserp/2.png" className="img-fluid w-100 rounded" alt="Image" />
              </div>
              <div className="service-title">
                <div className="service-title-name">
                  <div className="bg-primary text-center rounded p-3 mx-5 mb-4">
                    <a href="#" className="h4 text-white mb-0">Garment ERP</a>
                  </div>
                  <a className="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4" href="#">Explore More</a>
                </div>
                <div className="service-content pb-4">
                  <a href="#"><h4 className="text-white mb-4 py-3">Garment ERP</h4></a>
                  <div className="px-4">
                    <p className="mb-4">...</p>
                    <a className="btn btn-primary border-secondary rounded-pill text-white py-3 px-5" href="#">Explore More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.5s">
          <div className="service-item">
            <div className="service-inner">
              <div className="service-img">
                <img src="img/sarserp/11.png" className="img-fluid w-100 rounded" alt="Image" />
              </div>
              <div className="service-title">
                <div className="service-title-name">
                  <div className="bg-primary text-center rounded p-3 mx-5 mb-4">
                    <a href="#" className="h4 text-white mb-0">FootWear ERP</a>
                  </div>
                  <a className="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4" href="#">Explore More</a>
                </div>
                <div className="service-content pb-4">
                  <a href="#"><h4 className="text-white mb-4 py-3">FootWear ERP</h4></a>
                  <div className="px-4">
                    <p className="mb-4">...</p>
                    <a className="btn btn-primary border-secondary rounded-pill text-white py-3 px-5" href="#">Explore More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.1s">
          <div className="service-item">
            <div className="service-inner">
              <div className="service-img">
                <img src="img/sarserp/4.png" className="img-fluid w-100 rounded" alt="Image" />
              </div>
              <div className="service-title">
                <div className="service-title-name">
                  <div className="bg-primary text-center rounded p-3 mx-5 mb-4">
                    <a href="#" className="h4 text-white mb-0">Lifestyle & Fashion</a>
                  </div>
                  <a className="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4" href="#">Explore More</a>
                </div>
                <div className="service-content pb-4">
                  <a href="#"><h4 className="text-white mb-4 py-3">Lifestyle & Fashion ERP</h4></a>
                  <div className="px-4">
                    <p className="mb-4">...</p>
                    <a className="btn btn-primary border-secondary rounded-pill text-white py-3 px-5" href="#">Explore More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.3s">
          <div className="service-item">
            <div className="service-inner">
              <div className="service-img">
                <img src="img/sarserp/5.png" className="img-fluid w-100 rounded" alt="Image" />
              </div>
              <div className="service-title">
                <div className="service-title-name">
                  <div className="bg-primary text-center rounded p-3 mx-5 mb-4">
                    <a href="#" className="h4 text-white mb-0">FMCG ERP</a>
                  </div>
                  <a className="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4" href="#">Explore More</a>
                </div>
                <div className="service-content pb-4">
                  <a href="#"><h4 className="text-white mb-4 py-3">FMCG ERP</h4></a>
                  <div className="px-4">
                    <p className="mb-4">...</p>
                    <a className="btn btn-primary border-secondary rounded-pill text-white py-3 px-5" href="#">Explore More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.5s">
          <div className="service-item">
            <div className="service-inner">
              <div className="service-img">
                <img src="img/sarserp/6.png" className="img-fluid w-100 rounded" alt="Image" />
              </div>
              <div className="service-title">
                <div className="service-title-name">
                  <div className="bg-primary text-center rounded p-3 mx-5 mb-4">
                    <a href="#" className="h4 text-white mb-0">Electronics ERP</a>
                  </div>
                  <a className="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4" href="#">Explore More</a>
                </div>
                <div className="service-content pb-4">
                  <a href="#"><h4 className="text-white mb-4 py-3">Electronics ERP</h4></a>
                  <div className="px-4">
                    <p className="mb-4">...</p>
                    <a className="btn btn-primary border-secondary rounded-pill text-white py-3 px-5" href="#">Explore More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.1s">
          <div className="service-item">
            <div className="service-inner">
              <div className="service-img">
                <img src="img/sarserp/8.png" className="img-fluid w-100 rounded" alt="Image" />
              </div>
              <div className="service-title">
                <div className="service-title-name">
                  <div className="bg-primary text-center rounded p-3 mx-5 mb-4">
                    <a href="#" className="h4 text-white mb-0">Lifestyle & Fashion</a>
                  </div>
                  <a className="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4" href="#">Explore More</a>
                </div>
                <div className="service-content pb-4">
                  <a href="#"><h4 className="text-white mb-4 py-3">Lifestyle & Fashion ERP</h4></a>
                  <div className="px-4">
                    <p className="mb-4">...</p>
                    <a className="btn btn-primary border-secondary rounded-pill text-white py-3 px-5" href="#">Explore More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.3s">
          <div className="service-item">
            <div className="service-inner">
              <div className="service-img">
                <img src="img/sarserp/9.png" className="img-fluid w-100 rounded" alt="Image" />
              </div>
              <div className="service-title">
                <div className="service-title-name">
                  <div className="bg-primary text-center rounded p-3 mx-5 mb-4">
                    <a href="#" className="h4 text-white mb-0">FMCG ERP</a>
                  </div>
                  <a className="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4" href="#">Explore More</a>
                </div>
                <div className="service-content pb-4">
                  <a href="#"><h4 className="text-white mb-4 py-3">FMCG ERP</h4></a>
                  <div className="px-4">
                    <p className="mb-4">...</p>
                    <a className="btn btn-primary border-secondary rounded-pill text-white py-3 px-5" href="#">Explore More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.5s">
          <div className="service-item">
            <div className="service-inner">
              <div className="service-img">
                <img src="img/sarserp/12.png" className="img-fluid w-100 rounded" alt="Image" />
              </div>
              <div className="service-title">
                <div className="service-title-name">
                  <div className="bg-primary text-center rounded p-3 mx-5 mb-4">
                    <a href="#" className="h4 text-white mb-0">Electronics ERP</a>
                  </div>
                  <a className="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4" href="#">Explore More</a>
                </div>
                <div className="service-content pb-4">
                  <a href="#"><h4 className="text-white mb-4 py-3">Electronics ERP</h4></a>
                  <div className="px-4">
                    <p className="mb-4">...</p>
                    <a className="btn btn-primary border-secondary rounded-pill text-white py-3 px-5" href="#">Explore More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
  {/* Services End */}
  {/* Features Start */}
  <div className="container-fluid features overflow-hidden py-5">
    <div className="container">
      <div className="section-title text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="sub-style">
          <h5 className="sub-title text-primary px-3">Why Choose Us</h5>
        </div>
        <h1 className="display-5 mb-4">Why Choose Us</h1>
        <p className="mb-0">...</p>
      </div>
      <div className="row g-4 justify-content-center text-center">
        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
          <div className="feature-item text-center p-4">
            <div className="feature-icon p-3 mb-4">
              <i className="fas fa-dollar-sign fa-4x text-primary" />
            </div>
            <div className="feature-content d-flex flex-column">
              <h5 className="mb-3">Cost-Effective</h5>
              <p className="mb-3">Dolor, sit amet consectetur adipisicing elit. Soluta inventore cum accusamus,</p>
              <a className="btn btn-secondary rounded-pill" href="#">Read More<i className="fas fa-arrow-right ms-2" /></a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
          <div className="feature-item text-center p-4">
            <div className="feature-icon p-3 mb-4">
              {/* <i className="fab fa-cc-passport fa-4x text-primary" /> */}
              <i class="fab fa fa-globe fa-4x text-primary" ></i>
            </div>
            <div className="feature-content d-flex flex-column">
              <h5 className="mb-3">Globally</h5>
              <p className="mb-3">Dolor, sit amet consectetur adipisicing elit. Soluta inventore cum accusamus,</p>
              <a className="btn btn-secondary rounded-pill" href="#">Read More<i className="fas fa-arrow-right ms-2" /></a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
          <div className="feature-item text-center p-4">
            <div className="feature-icon p-3 mb-4">
              <i className="fas fa-atlas fa-4x text-primary" />
            </div>
            <div className="feature-content d-flex flex-column">
              <h5 className="mb-3">Education</h5>
              <p className="mb-3">Dolor, sit amet consectetur adipisicing elit. Soluta inventore cum accusamus,</p>
              <a className="btn btn-secondary rounded-pill" href="#">Read More<i className="fas fa-arrow-right ms-2" /></a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
          <div className="feature-item text-center p-4">
            <div className="feature-icon p-3 mb-4">
              <i className="fas fa-users fa-4x text-primary" />
            </div>
            <div className="feature-content d-flex flex-column">
              <h5 className="mb-3">HR</h5>
              <p className="mb-3">Dolor, sit amet consectetur adipisicing elit. Soluta inventore cum accusamus,</p>
              <a className="btn btn-secondary rounded-pill" href="#">Read More<i className="fas fa-arrow-right ms-2" /></a>
            </div>
          </div>
        </div>
        <div className="col-12">
          <a className="btn btn-primary border-secondary rounded-pill py-3 px-5 wow fadeInUp" data-wow-delay="0.1s" href="#">More Features</a>
        </div>
      </div>
    </div>
  </div>
  {/* Features End */}
  {/* Countries We Offer Start */}
  <div className="container-fluid country overflow-hidden py-5">
    <div className="container">
      <div className="section-title text-center wow fadeInUp" data-wow-delay="0.1s" style={{marginBottom: 70}}>
        <div className="sub-style">
          <h5 className="sub-title text-primary px-3">COUNTRIES WE WORK</h5>
        </div>
        <h1 className="display-5 mb-4">Website &amp; ERP Software Solusions & services following Countries</h1>
        <p className="mb-0">...</p>
      </div>
      <div className="row g-4 text-center">
        <div className="col-lg-6 col-xl-3 mb-5 mb-xl-0 wow fadeInUp" data-wow-delay="0.1s">
          <div className="country-item">
            <div className="rounded overflow-hidden">
              <img src="img/country-1.jpg" className="img-fluid w-100 rounded" alt="Image" />
            </div>
            <div className="country-flag">
              <img src="img/country-flag/srilanka.png" className="img-fluid rounded-circle" alt="Image" />
            </div>
            <div className="country-name">
              <a href="#" className="text-white fs-4">Srilanka</a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-3 mb-5 mb-xl-0 wow fadeInUp" data-wow-delay="0.3s">
          <div className="country-item">
            <div className="rounded overflow-hidden">
              <img src="img/country-2.jpg" className="img-fluid w-100 rounded" alt="Image" />
            </div>
            <div className="country-flag">
              <img src="img/country-flag/india.jpg" className="img-fluid rounded-circle" alt="Image" />
            </div>
            <div className="country-name">
              <a href="#" className="text-white fs-4">india</a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-3 mb-5 mb-xl-0 wow fadeInUp" data-wow-delay="0.5s">
          <div className="country-item">
            <div className="rounded overflow-hidden">
              <img src="img/country-3.jpg" className="img-fluid w-100 rounded" alt="Image" />
            </div>
            <div className="country-flag">
              <img src="img/country-flag/england.png" className="img-fluid rounded-circle" alt="Image" />
            </div>
            <div className="country-name">
              <a href="#" className="text-white fs-4">England</a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-3 mb-5 mb-xl-0 wow fadeInUp" data-wow-delay="0.7s">
          <div className="country-item">
            <div className="rounded overflow-hidden">
              <img src="img/country-4.jpg" className="img-fluid w-100 rounded" alt="Image" />
            </div>
            <div className="country-flag">
              <img src="img/country-flag/dubai.png" className="img-fluid rounded-circle" alt="Image" />
            </div>
            <div className="country-name">
              <a href="#" className="text-white fs-4">Dubai</a>
            </div>
          </div>
        </div>
        <div className="col-12">
          <a className="btn btn-primary border-secondary rounded-pill py-3 px-5 wow fadeInUp" data-wow-delay="0.1s" href="#">More Countries</a>
        </div>
      </div>
    </div>
  </div>
  {/* Countries We Offer End */}
  {/* Testimonial Start */}
  <div className="container-fluid testimonial overflow-hidden pb-5">
    <div className="container py-5">
      <div className="section-title text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="sub-style">
          <h5 className="sub-title text-primary px-3">OUR CLIENTS RIVIEWS</h5>
        </div>
        <h1 className="display-5 mb-4">What Our Clients Say</h1>
        <p className="mb-0">...</p>
      </div>
      <div className="owl-carousel testimonial-carousel wow zoomInDown" data-wow-delay="0.2s">
        <div className="testimonial-item">
          <div className="testimonial-content p-4 mb-5">
            <p className="fs-5 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitati eiusmod tempor incididunt.
            </p>
            <div className="d-flex justify-content-end">
              <i className="fas fa-star text-secondary" />
              <i className="fas fa-star text-secondary" />
              <i className="fas fa-star text-secondary" />
              <i className="fas fa-star text-secondary" />
              <i className="fas fa-star text-secondary" />
            </div>
          </div>
          <div className="d-flex">
            <div className="rounded-circle me-4" style={{width: 100, height: 100}}>
              <img className="img-fluid rounded-circle" src="img/testimonial-1.jpg" alt="img" />
            </div>
            <div className="my-auto">
              <h5>Person Name</h5>
              <p className="mb-0">Profession</p>
            </div>
          </div>
        </div>
        <div className="testimonial-item">
          <div className="testimonial-content p-4 mb-5">
            <p className="fs-5 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitati eiusmod tempor incididunt.
            </p>
            <div className="d-flex justify-content-end">
              <i className="fas fa-star text-secondary" />
              <i className="fas fa-star text-secondary" />
              <i className="fas fa-star text-secondary" />
              <i className="fas fa-star text-secondary" />
              <i className="fas fa-star text-secondary" />
            </div>
          </div>
          <div className="d-flex">
            <div className="rounded-circle me-4" style={{width: 100, height: 100}}>
              <img className="img-fluid rounded-circle" src="img/testimonial-2.jpg" alt="img" />
            </div>
            <div className="my-auto">
              <h5>Person Name</h5>
              <p className="mb-0">Profession</p>
            </div>
          </div>
        </div>
        <div className="testimonial-item">
          <div className="testimonial-content p-4 mb-5">
            <p className="fs-5 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitati eiusmod tempor incididunt.
            </p>
            <div className="d-flex justify-content-end">
              <i className="fas fa-star text-secondary" />
              <i className="fas fa-star text-secondary" />
              <i className="fas fa-star text-secondary" />
              <i className="fas fa-star text-secondary" />
              <i className="fas fa-star text-secondary" />
            </div>
          </div>
          <div className="d-flex">
            <div className="rounded-circle me-4" style={{width: 100, height: 100}}>
              <img className="img-fluid rounded-circle" src="img/testimonial-3.jpg" alt="img" />
            </div>
            <div className="my-auto">
              <h5>Person Name</h5>
              <p className="mb-0">Profession</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Testimonial End */}
  {/* Training Start */}
  <div className="container-fluid training overflow-hidden bg-light py-5">
    <div className="container py-5">
      <div className="section-title text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="sub-style">
          <h5 className="sub-title text-primary px-3">CHECK OUR TRAINING</h5>
        </div>
        <h1 className="display-5 mb-4">Get the Best Coacing Service Training</h1>
        <p className="mb-0">...</p>
      </div>
      <div className="row g-4">
        <div className="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
          <div className="training-item">
            <div className="training-inner">
              <img src="img/training/1.png" className="img-fluid w-100 rounded" alt="Image" />
              <div className="training-title-name">
                <a href="#" className="h4 text-white mb-0">Web Development</a>
                <a href="#" className="h4 text-white mb-0">Coaching</a>
              </div>
            </div>
            <div className="training-content bg-secondary rounded-bottom p-4">
              <a href="#"><h4 className="text-white">Web Development Coaching</h4></a>
              <p className="text-white-50">Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, veritatis.</p>
              <a className="btn btn-secondary rounded-pill text-white p-0" href="#">Read More <i className="fa fa-arrow-right" /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
          <div className="training-item">
            <div className="training-inner">
              <img src="img/training/2.png" className="img-fluid w-100 rounded" alt="Image" />
              <div className="training-title-name">
                <a href="#" className="h4 text-white mb-0">Mobile Development</a>
                <a href="#" className="h4 text-white mb-0">Coaching</a>
              </div>
            </div>
            <div className="training-content bg-secondary rounded-bottom p-4">
              <a href="#"><h4 className="text-white">Mobile Development</h4></a>
              <p className="text-white-50">Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, veritatis.</p>
              <a className="btn btn-secondary rounded-pill text-white p-0" href="#">Read More <i className="fa fa-arrow-right" /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
          <div className="training-item">
            <div className="training-inner">
              <img src="img/training/3.png" className="img-fluid w-100 rounded" alt="Image" />
              <div className="training-title-name">
                <a href="#" className="h4 text-white mb-0">Digital Marketing</a>
                <a href="#" className="h4 text-white mb-0">Coaching</a>
              </div>
            </div>
            <div className="training-content bg-secondary rounded-bottom p-4">
              <a href="#"><h4 className="text-white">Digital Marketing Coaching</h4></a>
              <p className="text-white-50">Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, veritatis.</p>
              <a className="btn btn-secondary rounded-pill text-white p-0" href="#">Read More <i className="fa fa-arrow-right" /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
          <div className="training-item">
            <div className="training-inner">
              <img src="img/training/4.png" className="img-fluid w-100 rounded" alt="Image" />
              <div className="training-title-name">
                <a href="#" className="h4 text-white mb-0">Software Testing</a>
                <a href="#" className="h4 text-white mb-0">Coaching</a>
              </div>
            </div>
            <div className="training-content bg-secondary rounded-bottom p-4">
              <a href="#"><h4 className="text-white">Software Testing Coaching</h4></a>
              <p className="text-white-50">Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, veritatis.</p>
              <a className="btn btn-secondary rounded-pill text-white p-0" href="#">Read More <i className="fa fa-arrow-right" /></a>
            </div>
          </div>
        </div>
        <div className="col-12 text-center">
          <a className="btn btn-primary border-secondary rounded-pill py-3 px-5 wow fadeInUp" data-wow-delay="0.1s" href="#">View More</a>
        </div>
      </div>
    </div>
  </div>
  {/* Training End */}
  {/* Contact Start */}
  <div className="container-fluid contact overflow-hidden pb-5">
    <div className="container py-5">
      <div className="office pt-5">
        <div className="section-title text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">
          <div className="sub-style">
            <h5 className="sub-title text-primary px-3">India's Offices</h5>
          </div>
          <h1 className="display-5 mb-4">Explore Our Office in India</h1>
          <p className="mb-0">...</p>
        </div>
        <div className="row g-4 justify-content-center">
          <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
            <div className="office-item p-4">
              <div className="office-img mb-4">
                <img src="img/office/1.png" className="img-fluid w-100 rounded" alt />
              </div>
              <div className="office-content d-flex flex-column">
                <h4 className="mb-2">Panipat Haryana</h4>
                <a href="#" className="text-secondary fs-5 mb-2">+91-9034905402</a>
                <a href="#" className="text-muted fs-5 mb-2">info@sarserpindia.com</a>
                <p className="mb-0">1329, First Floor, Sector 13-17, Panipat, Haryana, India.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
            <div className="office-item p-4">
              <div className="office-img mb-4">
                <img src="img/office/2.png" className="img-fluid w-100 rounded" alt />
              </div>
              <div className="office-content d-flex flex-column">
                <h4 className="mb-2">Mumbai Maharastra</h4>
                <a href="#" className="text-secondary fs-5 mb-2">+91-9034905402</a>
                <a href="#" className="text-muted fs-5 mb-2">info@sarserpindia.com</a>
                <p className="mb-0">Mumbai, Maharastra, India.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
            <div className="office-item p-4">
              <div className="office-img mb-4">
                <img src="img/office/3.png" className="img-fluid w-100 rounded" alt />
              </div>
              <div className="office-content d-flex flex-column">
                <h4 className="mb-2">Mohali Chandighad</h4>
                <a href="#" className="text-secondary fs-5 mb-2">+91-9034905402</a>
                <a href="#" className="text-muted fs-5 mb-2">info@sarserpindia.com</a>
                <p className="mb-0">Mohali, Chandigarh, India.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
            <div className="office-item p-4">
              <div className="office-img mb-4">
                <img src="img/office/4.png" className="img-fluid w-100 rounded" alt />
              </div>
              <div className="office-content d-flex flex-column">
                <h4 className="mb-2">Kota Office Rajsthan</h4>
                <a href="#" className="text-secondary fs-5 mb-2">+91-9034905402</a>
                <a href="#" className="text-muted fs-5 mb-2">info@sarserpindia.com</a>
                <p className="mb-0">Kota, Rajsthan, India</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Contact End */}
 </div>

    </>
  )
}

export default Home