import React from 'react'
import Visit from './Visit'

const Footer = () => {
  
  return (
    <>
    {/* Footer Start */}
  <div className="container-fluid footer py-4 wow fadeIn" data-wow-delay="0.2s">
    <div className="container py-1">
      <div className="row g-5" style={{fontSize:"13px"}}>

        <div className="col-md-6 col-lg-6 col-xl-3">
          <div className="footer-item d-flex flex-column">
            <h4 className="text-secondary mb-4">Quick Links</h4>
            {/* <a href><i className="fa fa-map-marker-alt me-2" /> 123 Street, New York, USA</a>
            <a href><i className="fas fa-envelope me-2" /> info@example.com</a>
            <a href><i className="fas fa-phone me-2" /> +012 345 67890</a>
            <a href className="mb-3"><i className="fas fa-print me-2" /> +012 345 67890</a>
            <div className="d-flex align-items-center">
              <i className="fas fa-share fa-2x text-secondary me-2" />
              <a className="btn mx-1" href><i className="fab fa-facebook-f" /></a>
              <a className="btn mx-1" href><i className="fab fa-twitter" /></a>
              <a className="btn mx-1" href><i className="fab fa-instagram" /></a>
              <a className="btn mx-1" href><i className="fab fa-linkedin-in" /></a>
            </div> */}
            <a href="#" className><i className="fas fa-angle-right me-2" />About Us</a>
            <a href="#" className><i className="fas fa-angle-right me-2" />Contact Us</a>
            <a href="#" className><i className="fas fa-angle-right me-2" />Request For Demo</a>
            <a href="#" className><i className="fas fa-angle-right me-2" />Become Our Partner</a>
            <a href="#" className><i className="fas fa-angle-right me-2" />Career with us</a>
            <a href="#" className><i className="fas fa-angle-right me-2" />Our Partners</a>
            <a href="#" className><i className="fas fa-angle-right me-2" />Our Esteemed Customers</a>
            <a href="#" className><i className="fas fa-angle-right me-2" />Free Live Demo</a>
            <a href="#" className><i className="fas fa-angle-right me-2" />Downloads</a>
            <a href="#" className><i className="fas fa-angle-right me-2" />Cloud Pricing</a>
            <a href="#" className><i className="fas fa-angle-right me-2" />On-Premises Pricing</a>
            <a href="#" className><i className="fas fa-angle-right me-2" />Events</a>
            <a href="#" className><i className="fas fa-angle-right me-2" />Customer Success Stories</a>
            <a href="#" className><i className="fas fa-angle-right me-2" />FAQs</a>
            <a href="#" className><i className="fas fa-angle-right me-2" />LOGIC ERP Blog</a>
            <a href="#" className><i className="fas fa-angle-right me-2" />LOGIC ERP Tutorials</a>
            
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-3">
          <div className="footer-item d-flex flex-column">
            <h4 className="text-secondary mb-4">Popular Products</h4>
            {/* <div className="mb-3">
              <h6 className="text-muted mb-0">Mon - Friday:</h6>
              <p className="text-white mb-0">09.00 am to 07.00 pm</p>
            </div>
            <div className="mb-3">
              <h6 className="text-muted mb-0">Satday:</h6>
              <p className="text-white mb-0">10.00 am to 05.00 pm</p>
            </div>
            <div className="mb-3">
              <h6 className="text-muted mb-0">Vacation:</h6>
              <p className="text-white mb-0">All Sunday is our vacation</p>
            </div> */}
            <a href className><i className="fas fa-angle-right me-2" />LOGIC Enterprise-Core</a>
            <a href className><i className="fas fa-angle-right me-2" />Omnichannel Retail</a>
            <a href className><i className="fas fa-angle-right me-2" />Billing Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Distribution Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Manufacturing Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Apparel & Footwear Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Apparel Distribution Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Apparel Manufacturing</a>
            <a href className><i className="fas fa-angle-right me-2" />Software For Supermarkets</a>
            <a href className><i className="fas fa-angle-right me-2" />Grocery Store Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Pharmacy Retail Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Pharma Distribution Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Electronic Shop Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Mobile Shop Software</a>
            <a href className><i className="fas fa-angle-right me-2" />F&B Retail Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Spa & Salon Software</a>
            
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-3">
          <div className="footer-item d-flex flex-column">
            <h4 className="text-secondary mb-4">Our Products</h4>
            <a href className><i className="fas fa-angle-right me-2" />Gym & Fitness Center</a>
            <a href className><i className="fas fa-angle-right me-2" />POS Software</a>
            <a href className><i className="fas fa-angle-right me-2" />GST Ready Software</a>
            <a href className><i className="fas fa-angle-right me-2" />GST e-Invoice Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Invoice Software</a>
            <a href className><i className="fas fa-angle-right me-2" />CRM-Loyalty Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Payroll Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Promotion & Campaign</a>
            <a href className><i className="fas fa-angle-right me-2" />Accounts and Finance Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Order Processing</a>
            <a href className><i className="fas fa-angle-right me-2" />Warehouse Management Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Inventory Management Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Production Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Business Insights</a>
            <a href className><i className="fas fa-angle-right me-2" />MIS Reporting</a>
            
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-3">
          <div className="footer-item d-flex flex-column">
            <h4 className="text-secondary mb-4">Mobile Apps</h4>
            <a href className><i className="fas fa-angle-right me-2" />e-Bill Mobile App</a>
            <a href className><i className="fas fa-angle-right me-2" />Delivery System</a>
            <a href className><i className="fas fa-angle-right me-2" />Online Ordering</a>
            <a href className><i className="fas fa-angle-right me-2" />Mobile POS Software</a>
            <a href className><i className="fas fa-angle-right me-2" />Mobile WMS</a>
            <a href className><i className="fas fa-angle-right me-2" />Online Ordering</a>
            <a href="#" className><i className="fas fa-angle-right me-2" /><img src='/img/footer/sars-gplaystore-icon.png' alt='google store' style={{width:"40%"}} /></a>
            <br />
            <a href="#" className><i className="fas fa-angle-right me-2" /><img src='/img/footer/sars-applestore-icon.png' alt='google store' style={{width:"40%"}} /></a>
            
            <div className="py-4">
              <h5 className="text-secondary mb-4" >connect with us on</h5>
              <div style={{paddingRight:"20px"}}>
              <a className="btn mx-1" href><i className="fab fa-facebook-f" /></a>
              <a className="btn mx-1" href><i className="fab fa-twitter" /></a>
              <a className="btn mx-1" href><i className="fab fa-instagram" /></a>
              <a className="btn mx-1" href><i className="fab fa-linkedin-in" /></a>

              </div>
              <br />
              <spam className="text-white"> <Visit /> </spam>
            </div>
          </div>
        </div>
        {/* <div className="col-md-6 col-lg-6 col-xl-3">
          <div className="footer-item">
            <h4 className="text-secondary mb-4">Newsletter</h4>
            <p className="text-white mb-3">Dolor amet sit justo amet elitr clita ipsum elitr est.Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <div className="position-relative mx-auto rounded-pill">
              <input className="form-control border-0 rounded-pill w-100 py-3 ps-4 pe-5" type="text" placeholder="Enter your email" />
              <button type="button" className="btn btn-primary rounded-pill position-absolute top-0 end-0 py-2 mt-2 me-2">SignUp</button>
            </div>
          </div>
        </div> */}

      </div>
    </div>
  </div>
  {/* Footer End */}
  {/* Copyright Start */}
  <div className="container-fluid copyright py-4">
    <div className="container">
      <div className="row g-2 align-items-center">
        <div className="col-md-5 text-center text-md-start mb-md-0">
          <span className="text-white"><a href="#" className="text-white">
            <i className="fas fa-copyright text-light me-2" />All right reserved.</a>  </span>
             
        </div>
        <div className="col-md-7 text-center text-md-end text-white">
          
          <a className=" text-white" href="#">Terms & Conditions</a>
          <a className=" text-white" href="#">Privacy Policy</a>
          <a className=" text-white" href="#">Cancellation & Refund Policy</a>
          <a className=" text-white" href="#">Shipping Policy</a>
          
        </div>
      </div>
    </div>
    
  </div>
  {/* Copyright End */}
  <a href="https://api.whatsapp.com/send?phone=+919034905402&text=Hello..." class="float" target="_blank">
   <i class="fa fa-whatsapp my-float"></i>
   </a>
  {/* Back to Top */}
  <a href="#" className="btn btn-primary btn-lg-square back-to-top"><i className="fa fa-arrow-up" /></a>
  
    </>
  )
}

export default Footer