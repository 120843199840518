import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <>
     {/* Topbar Start */}
  <div className="container-fluid bg-primary px-5 d-none d-lg-block">
    <div className="row gx-0 align-items-center">
      <div className="col-lg-5 text-center text-lg-start mb-lg-0">
        <div className="d-flex">
          <Link to="" className="text-muted me-4"><i className="fas fa-envelope text-secondary me-2" />sarserpindia@gmail.com</Link>
          <Link to="" className="text-muted me-0"><i className="fas fa-phone-alt text-secondary me-2" />0180-7964198</Link>
        </div>
      </div>
      <div className="col-lg-3 row-cols-1 text-center mb-2 mb-lg-0">
        <div className="d-inline-flex align-items-center" style={{height: 45}}>
          <Link className="btn btn-sm btn-outline-light btn-square rounded-circle me-2" to><i className="fab fa-twitter fw-normal text-secondary" /></Link>
          <Link className="btn btn-sm btn-outline-light btn-square rounded-circle me-2" to><i className="fab fa-facebook-f fw-normal text-secondary" /></Link>
          <Link className="btn btn-sm btn-outline-light btn-square rounded-circle me-2" to><i className="fab fa-linkedin-in fw-normal text-secondary" /></Link>
          <Link className="btn btn-sm btn-outline-light btn-square rounded-circle me-2" to><i className="fab fa-instagram fw-normal text-secondary" /></Link>
          <Link className="btn btn-sm btn-outline-light btn-square rounded-circle" to><i className="fab fa-youtube fw-normal text-secondary" /></Link>
        </div>
      </div>
      <div className="col-lg-4 text-center text-lg-end">
        <div className="d-inline-flex align-items-center" style={{height: 45}}>
          <Link to="#" className="text-muted me-2"> Help</Link><small> / </small>
          <Link to="#" className="text-muted mx-2"> Support</Link><small> / </small>
          <Link to="#" className="text-muted ms-2"> Contact</Link>
        </div>
      </div>
    </div>
  </div>
  {/* Topbar End */}
  {/* Navbar & Hero Start */}
  <div className="container-fluid nav-bar p-0">
    <nav className="navbar navbar-expand-lg navbar-light bg-white px-4 px-lg-5 py-3 py-lg-0">
      <Link to="/" className="navbar-brand p-0">
        <h1 className="display-5 text-secondary m-0"><img src="" className="img-fluid" alt />SARS ERP</h1>
        {/* <img src="img/logo.png" alt="Logo"> */}
      </Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
        <span className="fa fa-bars" />
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto py-0">

          {/* <Link to="" className="nav-item nav-link">Service</Link> */}
          <div className="nav-item dropdown">
            <Link to="#" className="nav-link" data-bs-toggle="dropdown"><span className="dropdown-toggle">Retail</span></Link>
            <div className="dropdown-menu m-0">
              <Link to="/fmcg-generalstore" className="dropdown-item">FMCG</Link>
              <Link to="/lf-home-decor-furniture" className="dropdown-item">Lifestyle </Link>
              <Link to="/lf-fashionjewellery" className="dropdown-item">Fashion</Link>
              <Link to="/fb-restaurant" className="dropdown-item">Food & Beverages</Link>
              <Link to="/wh-gymfitnesscenter" className="dropdown-item">Wellness & Salon</Link>
              <Link to="/electrical-home-appliances" className="dropdown-item">Electrical & Electronics</Link>
              {/* <Link to="" className="dropdown-item">Pharma & HealthCare</Link>
              <Link to="" className="dropdown-item">Apparel & Footwear</Link>
               */}
            </div>
          </div>
          <div className="nav-item dropdown">
            <Link to="" className="nav-link" data-bs-toggle="dropdown"><span className="dropdown-toggle">Distribution</span></Link>
            <div className="dropdown-menu m-0">
              <Link to="/transport" className="dropdown-item">Transport</Link>
              <Link to="/pharmahealthcare" className="dropdown-item">Pharma & Healthcare</Link>
              {/* <Link to="" className="dropdown-item">Apparel & Footwea</Link>
              <Link to="" className="dropdown-item">Garments</Link>
              <Link to="" className="dropdown-item">Textile</Link>
              <Link to="" className="dropdown-item">FMCG</Link>
              <Link to="" className="dropdown-item">Lifestyl & Fashion</Link>
              <Link to="" className="dropdown-item">Electrical & Electronics</Link>
              <Link to="" className="dropdown-item">Auto-Parts</Link> */}

            </div>
          </div>
          <div className="nav-item dropdown">
            <Link to="" className="nav-link" data-bs-toggle="dropdown"><span className="dropdown-toggle">Manufacturing</span></Link>
            <div className="dropdown-menu m-0">
              <Link to="/af-textile" className="dropdown-item">Textile</Link>
              {/* <Link to="" className="dropdown-item">Apparel</Link>
              <Link to="" className="dropdown-item">FootWear</Link>
              <Link to="" className="dropdown-item">Garments</Link>
              <Link to="" className="dropdown-item">FMCG</Link>
              <Link to="" className="dropdown-item">Food & Beverages</Link>
              <Link to="" className="dropdown-item">Electrical & Electronics</Link>
              <Link to="" className="dropdown-item">Auto-Parts</Link> */}

            </div>
          </div>
          {/* <div className="nav-item dropdown">
            <Link to="" className="nav-link" data-bs-toggle="dropdown"><span className="dropdown-toggle">ERP</span></Link>
            <div className="dropdown-menu m-0">
              <Link to="" className="dropdown-item">Apparel ERP</Link>
              <Link to="" className="dropdown-item">Garment ERP</Link>
              <Link to="" className="dropdown-item">FootWear ERP</Link>
              <Link to="" className="dropdown-item">textile ERP</Link>
              <Link to="" className="dropdown-item">Lifestyle & Fashion ERP</Link>
              <Link to="" className="dropdown-item">FMCG ERP</Link>
              <Link to="" className="dropdown-item">Beauty & Cosmetics ERP</Link>
              <Link to="" className="dropdown-item">Food & Beverages ERP</Link>
              <Link to="" className="dropdown-item">Electronics ERP</Link>
              <Link to="" className="dropdown-item">Auto Parts ERP</Link>
              <Link to="" className="dropdown-item">Hardware & Paints ERP</Link>

            </div>
          </div> */}
          <div className="nav-item dropdown">
            <Link to="" className="nav-link" data-bs-toggle="dropdown"><span className="dropdown-toggle">Company</span></Link>
            <div className="dropdown-menu m-0">
              <Link to="/about" className="dropdown-item">About Us</Link>
              <Link to="/ourcustomer" className="dropdown-item">Our Customers</Link>
              <Link to="" className="dropdown-item">B2B Cloud</Link>
              <Link to="" className="dropdown-item">Free Demo</Link>
              <Link to="" className="dropdown-item">Customer Success Stories</Link>
              <Link to="" className="dropdown-item">Events</Link>
              <Link to="" className="dropdown-item">Downloads</Link>
              <Link to="" className="dropdown-item">Our Partners</Link>
              <Link to="" className="dropdown-item">FAQs</Link>
              <Link to="" className="dropdown-item">Career with Us</Link>
              <Link to="" className="dropdown-item">Support</Link>

            </div>
          </div>
          {/* <div className="nav-item dropdown">
            <Link to="#" className="nav-link" data-bs-toggle="dropdown"><span className="dropdown-toggle">Pages</span></Link>
            <div className="dropdown-menu m-0">
              <Link to="" className="dropdown-item">Feature</Link>
              <Link to="" className="dropdown-item">Countries</Link>
              <Link to="" className="dropdown-item">Testimonial</Link>
              <Link to="" className="dropdown-item">Training</Link>
              <Link to="" className="dropdown-item">404 Page</Link>
            </div>
          </div> */}
          <div className="nav-item dropdown">
            <Link to="" className="nav-link" data-bs-toggle="dropdown"><span className="dropdown-toggle">Courses</span></Link>
            <div className="dropdown-menu m-0">
              <Link to="/reactcourse" className="dropdown-item">React Js</Link>
              <Link to="/angularcourse" className="dropdown-item">Angular Js</Link>
              <Link to="/mongodb-course" className="dropdown-item">Mongo DB</Link>
              <Link to="/nodejs-course" className="dropdown-item">Node Js</Link>
              <Link to="/mernstack" className="dropdown-item">MERN Stack</Link>
              <Link to="/meanstack" className="dropdown-item">MEAN Stack</Link>
              <Link to="/oop-dsaconcept" className="dropdown-item">OOPs & DSA</Link>
              <Link to="/phpexpert" className="dropdown-item">PHP Expert</Link>
              <Link to="/phpfullstack" className="dropdown-item">PHP Full Stack</Link>
              <Link to="/erp-pp-software" className="dropdown-item">ERP Software Training</Link>
              <Link to="/webdevelopment-training" className="dropdown-item">Web Development Training</Link>
              <Link to="/mobileapp-reactnative" className="dropdown-item">Mobile App Development</Link>
              <Link to="/b-digitalmarketing" className="dropdown-item">Digital Marketing</Link>
              <Link to="/a-digitalmarketing" className="dropdown-item">Advance Digital Marketing</Link>
              <Link to="" className="dropdown-item">etc...</Link>
              

            </div>
          </div>
        </div>
        {/* <button className="btn btn-primary btn-md-square border-secondary mb-3 mb-md-3 mb-lg-0 me-3" data-bs-toggle="modal" data-bs-target="#searchModal"><i className="fas fa-search" /></button> */}
        <Link to="/contact" className="btn btn-primary border-secondary rounded-pill py-2 px-4 px-lg-3 mb-3 mb-md-3 mb-lg-0">Contact Us</Link>
      </div>
    </nav>
  </div>
  {/* Navbar & Hero End */}
    </>
  )
}

export default Navbar