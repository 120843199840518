import React from 'react'


const AF_Textile = () => {
  return (
    <>
  <div className="container-fluid py-5">
    <div className="container">
      <div className="row">
    <div className=" wow fadeInRight" data-wow-delay="0.3s">
        <h5 className="sub-title pe-3">Apparel & Footwear Textile</h5>
          <h4 className="display-6 mb-4">Fully Customizable ERP Software for Textile Retail Stores </h4>
          <p className="mb-0 myFontS">Textile Manufacturing Software with comprehensive features like discrete and process manufacturing, multi-level BOM, cost management, MRP & merchandise planning, quality control, supply chain management, wastage control, reporting on mobile and much more.</p>
    </div>
    </div>
    </div>
    </div>
    <img src="img/retail/sars-manufacturing-textile.png" className="img-fluid w-100 "  alt="Image" />
    {/* <img src="img/retail/pharma-healthcare/sars-key.png" alt='image' style={{width:"100%", height:"400px"}} /> */}
  {/* About Start */}
  <div className="container-fluid py-5">
    <div className="container py-2">

      <div className="row g-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Proficient Process Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Sequential / Dependent Process Flows. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Discrete / Parallel Process Flows. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Assemble Process Flows. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Mill Process Flows. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Batch / Lot / Bundle Movement Process Flows. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> In House / Outsource or Job Working Process Flows. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Process Rejection Study / Standard Instruction for Processes / Quality Control Parameter Setup. </p>
                
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-af-textile.png" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
      </div>
      <div className="row pt-5" style={{backgroundColor:"#fcfcf7"}}>
        <div className="col-xl-5 mt-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-textile-inventry.png" className="img-fluid w-100 h-100 "  alt="Image" />
           
          </div>
        </div>
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Precise Inventory Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Stock keeping -SKU code / Lot Or Batch / Unique Pcs / Carton Code / Set Code. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Inventories with Different Batches / Barcoded / Non-Barcoded / Expiry / Waste / Return / Replacements. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Warehouse Space Management- Rack and Bin as per Product Category (Qty and Volume Base). </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Rack and Bin Auto mode/Manual mode for Put Away & Pick Up Suggestions with multiple matrix. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Multiple Stock Categories with different product matrix </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Stock Levels - Min / Max / Re-Order Levels. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Vendor wise Purchase Orders from Stock Level Shortages. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Process Department Wise Inventories. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> RM Issues to Processes Against Requisition Slip Generated from BOM. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Stock Verification Model for timely Audits. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Fabric Bale wise Inventories * For Garments. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> In and Out of Stock with Multiple Conversion Factor or UOM for single product. </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-5 pt-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h3 className="display-6 mb-5"> Configurable Bill of Material Management </h3>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Single Product BOM. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Multi-Level BOM - Single Product linked with Multiple Sub Products with Individual BOM. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> BOM can be defined - Per Pcs / Any Batch or Lot Size as per Product. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> BOM with estimated raw material cost and variance </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Customized Feedback and Surveys </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Instant Feedback at POS </p>
                
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded ">
            <img src="img/retail/sars-textile-bill.png" className="img-fluid w-100 h-100 "  alt="Image" />
            
          </div>
        </div>
      </div>
      <div className="row pt-5" style={{backgroundColor:"#fcfcf7"}}>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-textile-cost.png" className="img-fluid w-100 h-100 "  alt="Image" />
            
          </div>
        </div>
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-6 mb-4"> Explicit Cost Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Cost Per Product / Category </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Cost Per Batch / Lot </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Process Wise Cost Tracking </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Process + Part Wise Cost Tracking </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Piece Rate Employee Cost </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Direct involved cost </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Waste / Return / Consumption Cost Control </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Re-Working Cost </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Projected Vs Actual Cost Comparison </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-5 pt-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-7 mb-4"><b>Inch-Perfect Material Requirement Planning Management</b></h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Raw Materials Daily / Weekly / Fortnightly / Monthly / Quarterly / Yearly Plans. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Planning Based on Order Booking of Customers and Base Stocks. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Automated Raw Material Calculations. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Vendor wise Raw Material Lead time management. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Integrated Vendor Communication Portal. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Helps in planning and optimising the manufacturing capacity and material resources. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Generate E-Way bills </p>
                
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded mt-2">
            <img src="img/retail/sars-inventry.png" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
      </div>
      <div className="row pt-5" style={{backgroundColor:"#fcfcf7"}}>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-textile-supportive.png" className="img-fluid w-100 h-100 "  alt="Image" />
            
          </div>
        </div>
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-7 mb-4"><b>Supportive Merchandise Management</b></h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Deciding Product Priorities / Schedule for Production. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Product Images and documentary storage. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Tech Pack & Measurement Charts. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> E-Filling of Documents. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Purchase Order Budget / Closing Stock Limit Control. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Purchase Orders Linked with Work / Job / Production Orders. </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-5 pt-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-7 mb-4"><b>Intermingle Supply Chain Management</b></h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Sale Order Linking with Material Requirement Planning. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Customer Order Tracking Production Stage wise. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Better Customized Orders Handling. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Real Time Commitment to Customers. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Proper Control over Inventory from the beginning. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Warehouse Space Management and Seasonal Planning. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Price Fluctuations Study. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Control Warehouse processes and manage movements. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Respond faster to challenges and changes in supply and demand. </p>
                
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded mt-2">
            <img src="img/retail/sars-textile-supply.png" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
      </div>
      
    </div>
  </div>
  {/* About End */}
    </>
  )
}

export default AF_Textile