import React from 'react'


const PharmaHeathCare = () => {
  return (
    <>
   <div className="container-fluid py-5">
    <div className="container">
     <div className="row">
      <div className=" wow fadeInRight" data-wow-delay="0.3s">
        <h5 className="sub-title pe-3">Pharma & Health Care</h5>
          <h4 className="display-6 mb-4">Fully Customizable ERP Software for Healthcare Retail Stores </h4>
          <p className="mb-0 myFontS">Streamline your Pharma Wholesale & Distribution business with the most advanced Pharma Wholesale Software providing features like billing, inventory, expiry & breakage, price lists & markdowns, scheme & discounts, mobile app for order, warehouse & rack management, GST, E-Invoicing, reporting on mobile and much more.</p>
      </div>
     </div>
    </div>
   </div>
    <img src="img/retail/sars-pharma-key.png" className="img-fluid w-100" alt="Image" />
  {/* About Start */}
  <div className="container-fluid py-5">
    <div className="container py-2">

      <div className="row g-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-5 mb-4"> Billing </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-9 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Configurable billing screens. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Expiry/Near Expiry prompt at the time of billing </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Party Wise Credit/Bill Limit. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Party wise Payment Terms. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Party Wise Defaults (Discounts, Free Schemes, Rates etc.) </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> No Discount on special category of products. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Display Distributor/Retailer Margins at the time Billing. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Multiple Price Lists.  </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Batch wise Free Schemes. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Special checks for Schedule H & Narcotics items (Disallows user to sell the products without patient & Doctor details) </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Alert during the Billing if the Product Margin goes negative with Red color. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Last Rate Charged during the Billing of an Item Specially used for Generic Medicines. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Sale History Prompt in Sales Return to track quantity/discount in previous bills.  </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Convert Challans to the Bills with a Single Click.  </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Area wise Billing for accurate Customer Selection.  </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Auto Calculation of sale rate on basis of the scheme (FREE).  </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Auto batch wise scheme implementation as received in the purchase.  </p>
                
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 pt-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded mt-5">
            <img src="img/retail/sars-billing.png" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
      </div>
      <div className="row pt-5" style={{backgroundColor:"#fcfcf7"}}>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-inventry.png" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-5 mb-4"> Inventory Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Batch Wise/MRP wise stock. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Import Item Masters from Exce. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Fully Customized Barcode Generation. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Expired/Damage Stock Management. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Maintain Godown wise Stock. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Dead/Slow Moving Stock Reporting and Management. </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-5 pt-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
         
          <h2 className="display-5 mb-4"> Reporting </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Pre-Configured reporting template </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Configurable reports and dashboards </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Mobile Reporting App </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Email reports with Auto Report Mailer </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Power Reports and Dashboards </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Export to Excel/HTML/PDF </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Expired/Near Expiry Stock List (Supplier wise also available.) </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Sales analysis - Company / Division / Item. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Salesman/MR + Market + Party + Company + Division + Item wise analysis. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> EDashboard + Pivot Reporting. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> CD/TD/GST% wise analysis. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Additional FREE scheme reports. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Company + Division wise stock and sales. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Company + Division wise Expiry, Damage, and Near Expiry Stock. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Stock Movement Analysis Company + Division + Item wise. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Stock Aging Analysis Company + Division + Item wise. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Closing stock Company + Division + Item + Batch wise. </p>
                
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 pt-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded mt-5">
            <img src="img/retail/sars-reporting.png" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
      </div>

      <div className="row pt-5" style={{backgroundColor:"#fcfcf7"}}>
        <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-accounting.png" className="img-fluid w-100 h-100 "  alt="Image" />
          </div>
        </div>
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-5 mb-4"> Accounting & Financial Management </h2>
          <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Simple and Configurable Accounting Module. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Journal Entries, Cash/Bank Vouchers. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Bills Receivables/Payables. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Cost Centers, e-File GST Returns. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Balance Sheet, P&L, Ratio Analysis </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Cheque Printing, TDS/TCS Module. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Journal Purchase/Debit Note Entry HSN wise. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Journal Sale/Credit Notes Entry HSN wise. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> GST Summary and Auto-Entry of GST payable with Journal Entry. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Post Date Cheques Entry (Receipts/Payments) </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-5 pt-5">
        <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h2 className="display-5 mb-4"> Purchase </h2>
           <div className="row gy-4 align-items-center">
            <div className="col-8 col-md-12">
              <div className="mb-5">
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Buy in Boxes and Sell in Strips (Multiple units of conversion) </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Supplier Wise Defaults (Discounts, Schemes, Rates etc.) </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Supplier wise Rate/Last Scheme etc. at the time of Purchase Entry. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Generate landing cost based on user-defined formula. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Scheme alert during the purchase from the same supplier. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Create purchase returns for normal, expiry and damage stock, and track pendency for these vouchers against respective suppliers. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Import purchase from external file (excel/text) </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Auto Email Orders to Suppliers/MR etc. </p>
                <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Facility to Create Purchase Orders on Stock Level and Formula Basis. </p>
                
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 pt-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div className="bg-light rounded">
            <img src="img/retail/sars-purchage-health.png" className="img-fluid w-100 h-100 "  alt="Image" />
            </div>
        </div>
      </div>

    </div>
  </div>
  {/* About End */}
    </>
  )
}

export default PharmaHeathCare
  