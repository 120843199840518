import React from 'react'
import { Link } from 'react-router-dom'

const B_DigitalMarketing = () => {
  return (
    <>
    {/* Hero Start */}
<div className="container-fluid bg-breadcrumb" style={{width:"100%", height:"200px"}} >
 <div className="container text-center py-0 " >
   <h3 className="text-white display-6 mb-0 wow fadeInDown" data-wow-delay="0.1s">Digital Marketing</h3>
   <ol className="breadcrumb justify-content-center text-white mb-0 wow fadeInDown" data-wow-delay="0.3s">
     <li className="breadcrumb-item"><Link to="/" className="text-white">Home</Link></li>
     {/* <li className="breadcrumb-item"><Link href="#" className="text-white">Pages</Link></li> */}
     <li className="breadcrumb-item active text-secondary">Digital Marketing</li>
   </ol>
 </div>
</div>
{/* Hero End */}
 {/* Section Start */}
 <div className="container-fluid py-5">
   <div className="container py-3">
     <div className="row g-5">
       <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
         <h5 className="sub-title pe-3">Digital Marketing</h5>
         <h1 className="display-6 mb-4">Digital Marketing  </h1>
         <p className="mb-5">We take pride in offering top-notch training that equips you with the latest strategies and tools in digital marketing.</p>
         <div className="row gy-4 align-items-center">
           <div className="col-12 col-sm-6 d-flex align-items-center">
             <i className="fas fa-map-marked-alt fa-3x text-secondary" />
             <h5 className="ms-4">Best Trainers</h5>
           </div>
           <div className="col-12 col-sm-6 d-flex align-items-center">
             <i className="fas fa-passport fa-3x text-secondary" />
             <h5 className="ms-4">Digital Marketing</h5>
           </div>
           <div className="col-4 col-md-3">
             <div className="bg-light text-center rounded p-3">
               <div className="mb-2">
                 <i className="fas fa-ticket-alt fa-4x text-primary" />
               </div>
               <h1 className="display-5 fw-bold mb-2">4+</h1>
               <p className="text-muted mb-0">Years of Experience</p>
             </div>
           </div>
           <div className="col-8 col-md-9" >
             <div className="mb-4" style={{paddingLeft:"50px"}}>
               <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Social Media Marketing</p>
               <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Email Marketing</p>
               <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Content Marketing</p>
               <p className="text-primary h6 mb-3"><i className="fa fa-check-circle text-secondary me-2" /> Search Engine Optimization (SEO)</p>
               </div>
             <div className="d-flex flex-wrap">
               <div id="phone-tada" className="d-flex align-items-center justify-content-center me-4">
                 <a href className="position-relative wow tada" data-wow-delay=".9s">
                   <i className="fa fa-phone-alt text-primary fa-3x" />
                   <div className="position-absolute" style={{top: 0, left: 25}}>
                     <span><i className="fa fa-comment-dots text-secondary" /></span>
                   </div>
                 </a>
               </div>
               <div className="d-flex flex-column justify-content-center">
                 <span className="text-primary">Offer 100 % Genuine Assistance [Have any query?]</span>
                 <span className="text-secondary fw-bold fs-5" style={{letterSpacing: 2}}>Free: 0180-7964198</span>
               </div>
             </div>
           </div>
         </div>
       </div>

       <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
         <div className="bg-light rounded">
           <img src="img/training/2.png" className="img-fluid w-100 "  alt="Image" />
         </div>
       </div>
     </div>
     <div className="row py-5">
      <div className=" wow fadeInRight py-3" data-wow-delay="0.3s">
         <h4 className="display-6 mb-4">Digital Marketing Course in Panipat Haryana Overview</h4>
         
         <p className="text-primary">Are you searching for the <b> Best Digital Marketing Course in Panipat Haryana? </b> Discover SARS ERP - the leading <b> Digital Marketing Training Institute in Panipat Haryana.</b> We take pride in offering top-notch training that equips you with the latest strategies and tools in digital marketing. Dive into social media management, SEO techniques, and effective online advertising methods.</p>
         <p className="text-primary">Our experienced instructors guide you through practical exercises, ensuring you gain hands-on experience. Whether you're a beginner or a seasoned professional,<b> SARS ERP's Digital Marketing Training in Panipat Haryana </b> is designed to elevate your skills and propel your career forward in the dynamic world of digital marketing. Join us today and unleash your potential!</p>
         
         <p className="text-primary py-2 h3 mb-2"><i className="fa fa-arrow-circle-right text-secondary me-2" /> 
         What is Digital Marketing Course? </p>

         <p className="text-primary">Ever scrolled through social media and wondered how those cool ads got there? Or maybe you're curious about how websites appear at the top of search results? That's the magic of <b> digital marketing</b>, and a digital marketing course can teach you how to cast those spells yourself!</p>
         <p className="text-primary">Imagine you're running a lemonade stand in a crowded park. A <b> digital marketing training course </b> equips you with : </p>
         
         <p className="text-primary" ><b>A megaphone to call out to potential customers (think social media marketing!) </b></p>
         <p className="text-primary" ><b>Eye-catching signs to grab attention (learn about web design and content creation!) </b></p>
         <p className="text-primary" ><b>Social media skills to spread the word online (master the art of engaging posts!) </b></p>

         <p className="text-primary">This training goes beyond lemonade stands though. You'll learn how to use the power of the internet to promote any product or service. From creating engaging social media posts to making websites shine on search engines, a <b> digital marketing  course </b> covers a wide range of tools, including : </p>

         <p className="text-primary"><b>Social Media Marketing : </b> Leverage platforms like Facebook, Instagram, and Twitter to connect with your audience and build brand awareness. </p>
         <p className="text-primary"><b>Search Engine Optimization (SEO) : </b> Discover the secrets of making websites rank higher in search results, driving more organic traffic.</p>
         <p className="text-primary"><b>Content Marketing : </b> Craft compelling content (like blog posts, videos, and infographics) that engages your audience and positions you as an expert.</p>
         <p className="text-primary"><b>Email Marketing : </b> Build relationships with customers through targeted email campaigns that nurture leads and drive sales.</p>
         
         <p className="text-primary py-2 h3 mb-2"><i className="fa fa-caret-right text-secondary me-2" /> 
         What is Digital Marketing Course Fees? </p>
         <p className="text-primary" >Our <b> Digital Marketing course fees </b> are designed to be accessible and affordable for everyone. We understand the importance of learning without financial barriers. At SARS ERP, we offer competitive pricing that fits your budget, with flexible payment options available. You'll receive high-quality training from industry experts, comprehensive course materials, and hands-on experience—all at a reasonable cost. We believe in providing value for your investment in your eSARS ERPion and future career. </p>
         
         <p className="text-primary py-2 h3 mb-2"><i className="fa fa-caret-right text-secondary me-2" /> 
         Why Choose SARS ERP For Digital Marketing Course? </p>
         <p className="text-primary" >SARS ERP can be your springboard to success! Here's why SARS ERP stands out from the crowd : </p>
         
         <p className="text-primary" ><b>Industry Insiders as Your Teachers : </b> SARS ERP boasts experienced instructors who are current with the latest digital marketing trends. They're not just teachers; they're practical experts who can translate theory into real-world skills you can use to impress employers. </p>
         <p className="text-primary" ><b>Course Built for you : </b> Whether you're a complete beginner or looking to polish your existing skills, SARS ERP offers a comprehensive curriculum that caters to all levels. Learn in-demand areas like SEO (search engine optimization), social media marketing, content creation, and more - everything you need to become a digital marketing whiz. </p>
         <p className="text-primary" ><b>Get Hands-On with Projects : </b> SARS ERP doesn't just preach, they make you practice! You'll gain valuable experience by working on real-world projects throughout the course. This hands-on approach will help you build a strong portfolio to showcase your abilities to potential employers. </p>
         <p className="text-primary" ><b>Land Your Dream Job : </b> SARS ERP understands your ultimate goal - to launch a successful career in digital marketing. That's why they offer career assistance programs to help you craft a winning resume, ace job interviews, and stand out from the competition. </p>
         <p className="text-primary" ><b>Join a Supportive Community : </b> SARS ERP fosters a collaborative learning environment where you can connect with classmates, share ideas, and build a network of digital marketing professionals. This support system is invaluable as you navigate your career path. </p>
         <p className="text-primary" >SARS ERP offers the perfect blend of expert instruction, practical learning, and career guidance to prepare you for a thriving career in digital marketing. So, ditch the confusion and choose SARS ERP - your one-stop shop to becoming a digital marketing champion!</p>
         
         <p className="text-primary py-2 h3 mb-2"><i className="fa fa-caret-right text-secondary me-2" /> 
         How SARS ERP Provides Placement Assistance? </p>
         <p className="text-primary" >SARS ERP's Digital Marketing Course can be your launching pad! Not only will you gain in-demand skills, but SARS ERP boasts a unique advantage: 100% Placement Assistance. </p>
         <p className="text-primary" >That's right, after completing SARS ERP's comprehensive program, you'll have a dedicated team by your side, working tirelessly to connect you with exciting job opportunities. Here's how SARS ERP's Placement Assistance Program empowers you : </p>
         
         <p className="text-primary" ><b>Resume and Portfolio Building : </b> Craft a stellar resume that showcases your newfound digital marketing expertise. Plus, build an impressive portfolio to highlight your practical skills to potential employers. </p>
         <p className="text-primary" ><b>Mock Interviews and Career Guidance : </b> Conquer interview jitters with personalized mock interview sessions. Get valuable feedback and career advice to confidently navigate the job market. </p>
         <p className="text-primary" ><b>Industry Network : </b> SARS ERP leverages its strong industry connections to bridge the gap between you and potential employers. They'll actively seek out relevant job openings that match your skills and aspirations. </p>
         
         <p className="text-primary" >By combining exceptional training with dedicated placement support, SARS ERP goes beyond simply teaching you digital marketing. They invest in your future, ensuring you have the tools and confidence to land your dream job in the ever-evolving digital landscape.</p>
         <p className="text-primary" >So, if you're serious about making your mark in the exciting world of digital marketing, SARS ERP's 100% Placement Assistance program offers an unmatched advantage. </p>

         <p className="text-primary py-2 h3 mb-2"><i className="fa fa-caret-right text-secondary me-2" /> 
         Career After Digital Marketing Course </p>
         <p className="text-primary" >The digital world is booming, and businesses are constantly searching for skilled digital marketers to navigate their online presence. If you're passionate about creativity, technology, and connecting with people, then a <b> digital marketing training course in Panipat Haryana </b> from SARS ERP could be your key to a vibrant career. </p>
         <p className="text-primary" >SARS ERP's digital marketing training equips you with the in-demand skills to become a digital marketing maestro. You'll learn everything from website optimization (SEO) to social media marketing, email marketing, and even paid advertising (PPC). </p>
         <p className="text-primary" >But SARS ERP goes beyond just teaching you the tools. Their course emphasizes practical application, giving you real-world projects to build your portfolio and showcase your newfound expertise to potential employers. </p>
         
         <p className="text-primary py-2 h5 mb-2" >What kind of exciting careers can you unlock with SARS ERP's digital marketing training? </p>

         <p className="text-primary" ><b>Search Engine Optimization (SEO) Specialist. </b></p>
         <p className="text-primary" ><b>Social Media Marketing Manager </b></p>
         <p className="text-primary" ><b>Content Marketing Specialist </b></p>
         <p className="text-primary" ><b>Pay-Per-Click (PPC) Advertising Specialist </b></p>
         <p className="text-primary" ><b>Digital Marketing Analyst </b></p>

         <p className="text-primary" >SARS ERP's program is designed for anyone with a curious mind and a desire to learn. Whether you're a recent graduate or a working professional looking to switch gears, their experienced instructors will guide you every step of the way. </p>
         <p className="text-primary" >So, are you ready to transform your passion for the digital world into a fulfilling career? Enroll in SARS ERP's digital marketing training in Panipat Haryana today and watch your future take flight! </p>

      </div>
     </div>
   </div>
 </div>
 {/* Section End */}
   </>
  )
}

export default B_DigitalMarketing